import * as Sentry from '@sentry/react';
import { logger } from '../utils/logger';

export function initializeSentry(): void {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    logger.warn('Sentry DSN not configured. Error tracking disabled.');
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.MODE,
  });

  logger.info('Sentry initialized');
}

export function captureError(error: Error, context?: Record<string, any>): void {
  logger.error('Error captured:', error);
  
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.captureException(error, {
      extra: context,
    });
  }
}

export function setUserContext(userId: string, email?: string): void {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.setUser({
      id: userId,
      email: email,
    });
  }
}