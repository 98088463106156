import mammoth from 'mammoth';
import { logger } from '../../utils/logger';

export async function extractTextFromWord(file: File): Promise<string> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    
    if (!result.value) {
      throw new Error('No text content found in document');
    }

    logger.debug('Successfully extracted text from Word document', {
      fileSize: file.size,
      textLength: result.value.length
    });

    return result.value;
  } catch (error) {
    logger.error('Error extracting text from Word document:', error);
    throw new Error('Failed to process Word document');
  }
}