import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bell } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { useAuthStore } from '../../lib/store';
import { storageService } from '../../lib/services/storage.service';
import { UPDATES } from './data';
import { UpdatesModal } from './UpdatesModal';

export default function WhatsNew() {
  const { user } = useAuthStore();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [hasNewUpdates, setHasNewUpdates] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    if (!user) return;

    const lastViewedUpdate = storageService.get<string>(`last_viewed_update_${user.uid}`);
    const latestUpdate = UPDATES[0];

    if (!lastViewedUpdate || new Date(lastViewedUpdate) < new Date(latestUpdate.date)) {
      setHasNewUpdates(true);
    }
  }, [user]);

  const handleOpen = () => {
    setIsOpen(true);
    setIsAnimating(false);
    if (user) {
      storageService.set(`last_viewed_update_${user.uid}`, new Date().toISOString());
      setHasNewUpdates(false);
    }
  };

  // Only render if user is authenticated and not on home page
  if (!user || location.pathname === '/') {
    return null;
  }

  return (
    <>
      <motion.button
        onClick={handleOpen}
        className="fixed bottom-4 right-4 z-50 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors touch-manipulation"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Bell className="w-6 h-6" />
        {hasNewUpdates && isAnimating && (
          <motion.div
            className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full"
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ 
              repeat: Infinity, 
              duration: 2,
              ease: "easeInOut"
            }}
          />
        )}
      </motion.button>

      <AnimatePresence>
        {isOpen && <UpdatesModal onClose={() => setIsOpen(false)} />}
      </AnimatePresence>
    </>
  );
}