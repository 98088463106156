import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import QuizQuestion from '../components/quiz/QuizQuestion';
import PaywallModal from '../components/paywall/PaywallModal';
import { useQuizStore, useAuthStore } from '../lib/store';
import Button from '../components/Button';
import { analyticsService } from '../lib/services/analytics.service';

export default function Quiz() {
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const {
    questions,
    currentQuestionIndex,
    userAnswers,
    quizStartTime,
    setCurrentQuestionIndex,
    setUserAnswer,
    calculateScore,
  } = useQuizStore();

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  useEffect(() => {
    if (!quizStartTime) {
      useQuizStore.getState().setQuestions(questions);
    }
  }, [quizStartTime, questions]);

  useEffect(() => {
    if (!questions.length) {
      navigate('/create');
    }
  }, [questions, navigate]);

  const handleAnswer = (answer: string | boolean) => {
    if (user) {
      analyticsService.trackEvent('question_answered', {
        userId: user.uid,
        questionId: currentQuestion.id,
        questionType: currentQuestion.type,
      });
    }
    setUserAnswer(currentQuestion.id, answer);
  };

  const handleNext = () => {
    if (isLastQuestion) {
      navigate('/results');
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  if (!currentQuestion) {
    return (
      <div className="min-h-screen bg-gray-50 pt-20 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">No Quiz Found</h2>
          <Button onClick={() => navigate('/create')}>Create a Quiz</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="container max-w-2xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-lg shadow-lg p-6"
        >
          <AnimatePresence mode="wait">
            <QuizQuestion
              key={currentQuestion.id}
              question={currentQuestion}
              userAnswer={userAnswers[currentQuestion.id]}
              onAnswer={handleAnswer}
              onNext={handleNext}
              questionNumber={currentQuestionIndex + 1}
              totalQuestions={questions.length}
            />
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
}