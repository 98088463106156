import { Achievement } from '../../../types/achievements';

export const ACHIEVEMENTS: Achievement[] = [
  {
    id: 'first-quiz',
    name: 'First Quiz Completed',
    description: 'Complete your first quiz!',
    badge: 'bronze',
    icon: '🥉',
    requirement: 'Complete 1 quiz'
  },
  {
    id: 'high-scorer',
    name: 'High Scorer',
    description: 'Score 90% or higher on a quiz',
    badge: 'silver',
    icon: '🥈',
    requirement: 'Score 90%+ on any quiz'
  },
  {
    id: 'quiz-streak',
    name: 'Quiz Streak',
    description: 'Complete a quiz every day for 3 days',
    badge: 'gold',
    icon: '🥇',
    requirement: '3 day quiz streak'
  },
  {
    id: 'category-explorer',
    name: 'Category Explorer',
    description: 'Complete quizzes in 5 different categories',
    badge: 'silver',
    icon: '🌟',
    requirement: '5 different categories'
  },
  {
    id: 'quiz-master',
    name: 'Quiz Master',
    description: 'Complete 50 quizzes with an average score of 80%+',
    badge: 'gold',
    icon: '👑',
    requirement: '50 quizzes, 80%+ avg'
  }
];