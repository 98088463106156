import { collection, doc, setDoc, getDoc, getDocs, query, where, orderBy, Timestamp, limit, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { logger } from '../utils/logger';
import { QuizResult, SavedQuiz } from '../../types/quiz';
import { validateQuizResult } from './quiz-validation.service';

export async function saveQuizResult(result: QuizResult): Promise<string> {
  try {
    validateQuizResult(result);
    
    const resultData = {
      ...result,
      createdAt: Timestamp.now(),
      timeSpent: Math.max(1, result.timeSpent || 0),
      score: Math.round(result.score),
    };

    const resultRef = doc(collection(db, 'results'));
    await setDoc(resultRef, resultData);
    
    logger.info('Quiz result saved successfully', { quizId: resultRef.id });
    return resultRef.id;
  } catch (error) {
    logger.error('Error saving quiz result:', error);
    throw error;
  }
}

export async function getUserResults(userId: string): Promise<QuizResult[]> {
  try {
    const resultsRef = collection(db, 'results');
    const q = query(
      resultsRef, 
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(50)
    );
    
    const querySnapshot = await getDocs(q);
    const results: QuizResult[] = [];
    
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      results.push({
        ...data,
        quizId: doc.id,
        createdAt: data.createdAt.toDate(),
        timeSpent: Math.max(1, data.timeSpent || 0),
        score: Math.round(data.score || 0)
      } as QuizResult);
    });
    
    logger.info('Retrieved user results', { count: results.length });
    return results;
  } catch (error) {
    logger.error('Error getting user results:', error);
    throw new Error('Failed to retrieve quiz results');
  }
}

export async function getQuizById(quizId: string): Promise<SavedQuiz | null> {
  try {
    const quizRef = doc(db, 'results', quizId);
    const quizDoc = await getDoc(quizRef);
    
    if (!quizDoc.exists()) {
      return null;
    }

    const data = quizDoc.data();
    return {
      id: quizDoc.id,
      userId: data.userId,
      topic: data.topic,
      difficulty: data.difficulty,
      questions: data.questions,
      createdAt: data.createdAt.toDate(),
      lastTaken: data.lastTaken ? data.lastTaken.toDate() : undefined,
      timesCompleted: data.timesCompleted || 1
    };
  } catch (error) {
    logger.error('Error getting quiz by id:', error);
    throw new Error('Failed to retrieve quiz');
  }
}

export async function clearUserHistory(userId: string): Promise<void> {
  try {
    const resultsRef = collection(db, 'results');
    const q = query(resultsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    
    const batch = writeBatch(db);
    querySnapshot.forEach((docSnapshot) => {
      batch.delete(docSnapshot.ref);
    });
    
    await batch.commit();
    logger.info('User history cleared successfully', { userId });
  } catch (error) {
    logger.error('Error clearing user history:', error);
    throw new Error('Failed to clear quiz history');
  }
}