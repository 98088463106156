import { ReportHandler } from 'web-vitals';
import { logger } from '../utils/logger';
import { performanceMonitor } from './performance';
import { analyticsService } from './analytics';

export function reportWebVitals(onPerfEntry?: ReportHandler): void {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(metric => {
        onPerfEntry(metric);
        logger.debug('CLS:', metric);
        performanceMonitor.recordWebVital('CLS', metric.value);
        analyticsService.trackEvent('web_vital', {
          name: 'CLS',
          value: metric.value,
          rating: metric.rating,
        });
      });
      
      getFID(metric => {
        onPerfEntry(metric);
        logger.debug('FID:', metric);
        performanceMonitor.recordWebVital('FID', metric.value);
        analyticsService.trackEvent('web_vital', {
          name: 'FID',
          value: metric.value,
          rating: metric.rating,
        });
      });
      
      getFCP(metric => {
        onPerfEntry(metric);
        logger.debug('FCP:', metric);
        performanceMonitor.recordWebVital('FCP', metric.value);
        analyticsService.trackEvent('web_vital', {
          name: 'FCP',
          value: metric.value,
          rating: metric.rating,
        });
      });
      
      getLCP(metric => {
        onPerfEntry(metric);
        logger.debug('LCP:', metric);
        performanceMonitor.recordWebVital('LCP', metric.value);
        analyticsService.trackEvent('web_vital', {
          name: 'LCP',
          value: metric.value,
          rating: metric.rating,
        });
      });
      
      getTTFB(metric => {
        onPerfEntry(metric);
        logger.debug('TTFB:', metric);
        performanceMonitor.recordWebVital('TTFB', metric.value);
        analyticsService.trackEvent('web_vital', {
          name: 'TTFB',
          value: metric.value,
          rating: metric.rating,
        });
      });
    });
  }
}