import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { logger } from '../utils/logger';

export const analyticsService = {
  trackEvent(eventName: string, params?: Record<string, any>) {
    try {
      if (analytics) {
        logEvent(analytics, eventName, params);
        logger.debug('Analytics event tracked:', { eventName, params });
      }
    } catch (error) {
      logger.error('Error tracking analytics event:', error);
    }
  },

  trackQuizCreation(userId: string, quizType: string) {
    this.trackEvent('quiz_created', {
      userId,
      quizType,
      timestamp: Date.now(),
    });
  },

  trackQuizCompletion(userId: string, quizId: string, score: number) {
    this.trackEvent('quiz_completed', {
      userId,
      quizId,
      score,
      timestamp: Date.now(),
    });
  },

  trackSubscription(userId: string, plan: string) {
    this.trackEvent('subscription_purchased', {
      userId,
      plan,
      timestamp: Date.now(),
    });
  },

  trackError(error: Error, context?: Record<string, any>) {
    this.trackEvent('error_occurred', {
      errorMessage: error.message,
      errorStack: error.stack,
      context,
      timestamp: Date.now(),
    });
  }
};