import React, { useState } from 'react';
import { AlertTriangle } from 'lucide-react';
import Button from '../Button';
import Modal from '../ui/Modal';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  isDeleting: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function DeleteConfirmationModal({
  isOpen,
  isDeleting,
  onConfirm,
  onCancel
}: DeleteConfirmationModalProps) {
  const [step, setStep] = useState(1);
  const [confirmText, setConfirmText] = useState('');

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (confirmText.toLowerCase() === 'delete') {
      onConfirm();
    }
  };

  const handleClose = () => {
    setStep(1);
    setConfirmText('');
    onCancel();
  };

  const renderActions = () => (
    <>
      <Button
        variant="outline"
        onClick={step === 1 ? handleClose : () => setStep(1)}
        className="w-full sm:w-auto"
      >
        {step === 1 ? 'Cancel' : 'Back'}
      </Button>
      <Button
        onClick={handleNext}
        disabled={step === 2 && confirmText.toLowerCase() !== 'delete' || isDeleting}
        className="w-full sm:w-auto bg-red-600 hover:bg-red-700"
      >
        {isDeleting ? 'Deleting...' : step === 1 ? 'Continue' : 'Delete Account'}
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Delete Account"
      icon={<AlertTriangle className="w-6 h-6" />}
      iconColor="text-red-600"
      actions={renderActions()}
    >
      {step === 1 ? (
        <div className="space-y-4">
          <p className="text-gray-600">
            Before deleting your account, please consider:
          </p>
          <div className="space-y-3">
            <div className="flex items-center space-x-3 p-3 bg-yellow-50 rounded-lg">
              <div className="w-2 h-2 rounded-full bg-yellow-500" />
              <span>You have a 30-day window to recover your account</span>
            </div>
            <div className="flex items-center space-x-3 p-3 bg-yellow-50 rounded-lg">
              <div className="w-2 h-2 rounded-full bg-yellow-500" />
              <span>All your quiz progress and achievements will be archived</span>
            </div>
            <div className="flex items-center space-x-3 p-3 bg-yellow-50 rounded-lg">
              <div className="w-2 h-2 rounded-full bg-yellow-500" />
              <span>You can download your data before deletion</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <p className="text-gray-600">
            This action cannot be undone. Please type <strong>delete</strong> to confirm:
          </p>
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            className="w-full px-4 py-3 border rounded-xl text-lg"
            placeholder="Type 'delete' to confirm"
            autoFocus
          />
        </div>
      )}
    </Modal>
  );
}