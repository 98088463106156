import React from 'react';
import { Info } from 'lucide-react';
import { User } from 'firebase/auth';

interface AccountSectionProps {
  user: User | null;
  onShowAccountInfo: () => void;
}

export default function AccountSection({ user, onShowAccountInfo }: AccountSectionProps) {
  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
      <h2 className="text-xl font-bold mb-6">Account Settings</h2>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email Address
          </label>
          <input
            type="email"
            value={user?.email || ''}
            disabled
            className="w-full px-4 py-2 border rounded-xl bg-gray-50 text-gray-600"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Account Type
          </label>
          <div className="space-y-2">
            <div className="flex items-center gap-3">
              <input
                type="text"
                value="Essential"
                disabled
                className="w-full px-4 py-2 border rounded-xl bg-gray-50 text-gray-600"
              />
              <span className="px-2 py-1 bg-blue-100 text-blue-700 text-xs font-medium rounded">
                Beta
              </span>
            </div>
            <button
              onClick={onShowAccountInfo}
              className="flex items-center text-sm text-blue-600 hover:text-blue-700"
            >
              <Info className="w-4 h-4 mr-1" />
              Learn more about account types
            </button>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Member Since
          </label>
          <input
            type="text"
            value={user?.metadata.creationTime ? new Date(user.metadata.creationTime).toLocaleDateString() : '-'}
            disabled
            className="w-full px-4 py-2 border rounded-xl bg-gray-50 text-gray-600"
          />
        </div>
      </div>
    </div>
  );
}