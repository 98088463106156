import React from 'react';
import Button from '../Button';

interface DangerZoneProps {
  onDeleteAccount: () => void;
}

export default function DangerZone({ onDeleteAccount }: DangerZoneProps) {
  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 border-t-2 border border-red-500">
      <h2 className="text-xl font-bold mb-4 text-red-600">Danger Zone</h2>
      <p className="text-gray-600 mb-6">
        Once you delete your account, there is no going back. Please be certain.
      </p>
      <Button
        variant="outline"
        className="text-red-600 border-red-600 hover:bg-red-50 transition-all duration-300"
        onClick={onDeleteAccount}
      >
        Delete Account
      </Button>
    </div>
  );
}