import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { logger } from '../utils/logger';
import { getUserResults } from './quiz.service';
import { Achievement, UserAchievement } from '../../types/achievements';
import { handleFirebaseError } from '../utils/firebase-error-handler';
import { ACHIEVEMENTS } from './achievements/types';
import { 
  calculateStreakDays, 
  calculateCategoryProgress, 
  calculateQuizMasterProgress 
} from './achievements/progress.service';

async function isAchievementUnlocked(userId: string, achievementId: string): Promise<boolean> {
  try {
    const achievementRef = doc(db, 'userAchievements', `${userId}_${achievementId}`);
    const achievementDoc = await getDoc(achievementRef);
    return achievementDoc.exists();
  } catch (error) {
    handleFirebaseError(error, 'isAchievementUnlocked');
    return false;
  }
}

async function unlockAchievement(userId: string, achievementId: string): Promise<void> {
  try {
    const achievementRef = doc(db, 'userAchievements', `${userId}_${achievementId}`);
    
    // Check if already unlocked to prevent duplicates
    const existing = await getDoc(achievementRef);
    if (existing.exists()) {
      return;
    }

    const achievement: UserAchievement = {
      userId,
      achievementId,
      unlockedAt: new Date()
    };
    
    await setDoc(achievementRef, {
      ...achievement,
      unlockedAt: Timestamp.fromDate(achievement.unlockedAt)
    });
    
    logger.info(`Achievement unlocked: ${achievementId}`, { userId });
  } catch (error) {
    handleFirebaseError(error, 'unlockAchievement');
  }
}

export async function checkAndUpdateAchievements(userId: string): Promise<Achievement[]> {
  try {
    const results = await getUserResults(userId);
    const unlockedAchievements: Achievement[] = [];

    // Only proceed if there are quiz results
    if (results.length === 0) {
      return [];
    }

    // Check achievements in sequence
    const checks = [
      // First Quiz - Only unlock if this is their first quiz
      async () => {
        if (results.length === 1 && !(await isAchievementUnlocked(userId, 'first-quiz'))) {
          await unlockAchievement(userId, 'first-quiz');
          unlockedAchievements.push(ACHIEVEMENTS.find(a => a.id === 'first-quiz')!);
        }
      },

      // High Scorer
      async () => {
        if (!(await isAchievementUnlocked(userId, 'high-scorer')) && 
            results.some(result => result.score >= 90)) {
          await unlockAchievement(userId, 'high-scorer');
          unlockedAchievements.push(ACHIEVEMENTS.find(a => a.id === 'high-scorer')!);
        }
      },

      // Quiz Streak
      async () => {
        const streakDays = calculateStreakDays(results);
        if (!(await isAchievementUnlocked(userId, 'quiz-streak')) && 
            streakDays >= 3) {
          await unlockAchievement(userId, 'quiz-streak');
          unlockedAchievements.push(ACHIEVEMENTS.find(a => a.id === 'quiz-streak')!);
        }
      },

      // Category Explorer
      async () => {
        const { uniqueCategories } = calculateCategoryProgress(results);
        if (!(await isAchievementUnlocked(userId, 'category-explorer')) && 
            uniqueCategories >= 5) {
          await unlockAchievement(userId, 'category-explorer');
          unlockedAchievements.push(ACHIEVEMENTS.find(a => a.id === 'category-explorer')!);
        }
      },

      // Quiz Master
      async () => {
        const { quizCount, averageScore } = calculateQuizMasterProgress(results);
        if (!(await isAchievementUnlocked(userId, 'quiz-master')) && 
            quizCount >= 50 && 
            averageScore >= 80) {
          await unlockAchievement(userId, 'quiz-master');
          unlockedAchievements.push(ACHIEVEMENTS.find(a => a.id === 'quiz-master')!);
        }
      }
    ];

    // Execute checks in sequence
    for (const check of checks) {
      await check();
    }

    return unlockedAchievements;
  } catch (error) {
    handleFirebaseError(error, 'checkAndUpdateAchievements');
    return [];
  }
}