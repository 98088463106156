import React from 'react';
import { motion } from 'framer-motion';
import { Check, X } from 'lucide-react';
import { Question } from '../../types/quiz';
import { validateAnswer } from '../../lib/utils/answer-validation';

interface QuestionReviewProps {
  question: Question;
  userAnswer?: string | boolean;
  index: number;
}

export default function QuestionReview({ question, userAnswer, index }: QuestionReviewProps) {
  const isCorrect = validateAnswer(question.type, userAnswer, question.correctAnswer);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className={`p-6 rounded-lg mb-4 ${
        isCorrect ? 'bg-green-50' : 'bg-red-50'
      }`}
    >
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <h3 className="font-medium mb-2">Question {index + 1}</h3>
          <p className="text-gray-800 mb-4">{question.text}</p>
          
          <div className="space-y-2">
            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-600 mr-2">Your answer:</span>
              <span>{String(userAnswer)}</span>
            </div>
            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-600 mr-2">Correct answer:</span>
              <span>{String(question.correctAnswer)}</span>
            </div>
          </div>
        </div>
        <div className={`ml-4 p-2 rounded-full ${
          isCorrect ? 'bg-green-100' : 'bg-red-100'
        }`}>
          {isCorrect ? (
            <Check className="w-5 h-5 text-green-600" />
          ) : (
            <X className="w-5 h-5 text-red-600" />
          )}
        </div>
      </div>
      
      {question.explanation && (
        <div className="mt-4 text-sm bg-white bg-opacity-50 p-3 rounded">
          <span className="font-medium">Explanation:</span> {question.explanation}
        </div>
      )}
    </motion.div>
  );
}