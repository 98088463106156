import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  User,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { auth } from '../firebase';
import { logger } from '../utils/logger';
import { captureError } from '../monitoring/sentry';
import { performanceMonitor } from '../monitoring/performance';
import { createInitialUserProfile } from './auth.service';
import { useQuizStore } from '../store';

// Set persistence to LOCAL to maintain user session
setPersistence(auth, browserLocalPersistence).catch((error) => {
  logger.error('Error setting auth persistence:', error);
});

export async function signIn(email: string, password: string): Promise<User> {
  return performanceMonitor.measureAsync('auth_sign_in', async () => {
    try {
      logger.debug('Attempting to sign in user:', { email });
      const result = await signInWithEmailAndPassword(auth, email, password);
      logger.info('User signed in successfully:', { uid: result.user.uid });
      return result.user;
    } catch (error: any) {
      logger.error('Error signing in user:', error);
      captureError(error);
      throw new Error(getAuthErrorMessage(error.code));
    }
  });
}

export async function signUp(email: string, password: string, firstName: string, lastName: string): Promise<User> {
  return performanceMonitor.measureAsync('auth_sign_up', async () => {
    try {
      logger.debug('Attempting to create new user:', { email });
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      await createInitialUserProfile(result.user);

      // Initialize quiz store for new user
      useQuizStore.getState().resetStore();
      
      logger.info('User created successfully:', { uid: result.user.uid });
      return result.user;
    } catch (error: any) {
      logger.error('Error creating new user:', error);
      captureError(error);
      throw new Error(getAuthErrorMessage(error.code));
    }
  });
}

export async function signOut(): Promise<void> {
  try {
    logger.debug('Attempting to sign out user');
    
    // Clear the quiz store before signing out
    useQuizStore.getState().resetStore();
    
    // Clear localStorage
    localStorage.clear();
    
    await firebaseSignOut(auth);
    logger.info('User signed out successfully');
  } catch (error: any) {
    logger.error('Error signing out user:', error);
    captureError(error);
    throw new Error('Failed to sign out');
  }
}

function getAuthErrorMessage(code: string): string {
  switch (code) {
    case 'auth/invalid-email':
      return 'Invalid email address';
    case 'auth/user-disabled':
      return 'This account has been disabled';
    case 'auth/user-not-found':
      return 'No account found with this email';
    case 'auth/wrong-password':
      return 'Incorrect password';
    case 'auth/email-already-in-use':
      return 'An account already exists with this email';
    case 'auth/weak-password':
      return 'Password should be at least 6 characters';
    case 'auth/network-request-failed':
      return 'Network error occurred. Please check your connection';
    case 'auth/too-many-requests':
      return 'Too many attempts. Please try again later';
    case 'auth/operation-not-allowed':
      return 'This sign-in method is not enabled';
    case 'auth/invalid-credential':
      return 'Invalid credentials provided';
    default:
      return 'Authentication failed. Please try again.';
  }
}