import { DocumentFile, DocumentType } from './types';
import { validateDocument } from './validation.service';
import { extractTextFromWord } from './word.service';
import { extractTextFromPDF } from '../pdf';
import { logger } from '../../utils/logger';

export async function extractTextFromDocument(file: File): Promise<DocumentFile> {
  try {
    const validation = validateDocument(file);
    if (!validation.isValid) {
      throw new Error(validation.error);
    }

    const extension = file.name.split('.').pop()?.toLowerCase() as DocumentType;
    let text: string;

    switch (extension) {
      case 'pdf':
        text = await extractTextFromPDF(file);
        break;
      case 'doc':
      case 'docx':
        text = await extractTextFromWord(file);
        break;
      default:
        throw new Error('Unsupported file type');
    }

    return {
      file,
      type: extension,
      text
    };
  } catch (error) {
    logger.error('Error processing document:', error);
    throw error instanceof Error ? error : new Error('Failed to process document');
  }
}