import React from 'react';
import { Link } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { menuItems } from './MenuItems';

interface UserMenuProps {
  onSignOut: () => void;
  onClose?: () => void;
  isMobile?: boolean;
}

export default function UserMenu({ onSignOut, onClose, isMobile = false }: UserMenuProps) {
  const handleItemClick = () => {
    onClose?.();
  };

  return (
    <>
      {menuItems.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={`flex items-center space-x-3 px-4 py-2.5 text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors ${
            isMobile ? 'py-2' : ''
          }`}
          onClick={handleItemClick}
        >
          <item.icon className="w-4 h-4" />
          <span>{item.label}</span>
        </Link>
      ))}
      <hr className="my-2" />
      <button
        onClick={onSignOut}
        className="flex items-center space-x-3 px-4 py-2.5 text-gray-700 hover:bg-gray-50 hover:text-red-600 transition-colors w-full"
      >
        <LogOut className="w-4 h-4" />
        <span>Sign Out</span>
      </button>
    </>
  );
}