import React from 'react';
import { motion } from 'framer-motion';
import { Question } from '../../types/quiz';

interface QuestionOptionsProps {
  question: Question;
  userAnswer?: string | boolean;
  onAnswer: (answer: string | boolean) => void;
}

export default function QuestionOptions({ question, userAnswer, onAnswer }: QuestionOptionsProps) {
  return (
    <div className="space-y-3">
      {question.type === 'multiple-choice' && question.options?.map((option) => (
        <motion.button
          key={option}
          onClick={() => onAnswer(option)}
          className={`w-full p-4 text-left rounded-lg border transition-colors ${
            userAnswer === option
              ? 'border-blue-500 bg-blue-50 text-blue-700'
              : 'border-gray-200 hover:border-blue-300'
          }`}
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          {option}
        </motion.button>
      ))}

      {question.type === 'true-false' && (
        <div className="flex gap-4">
          {['true', 'false'].map((value) => (
            <motion.button
              key={value}
              onClick={() => onAnswer(value === 'true')}
              className={`flex-1 p-4 text-center rounded-lg border transition-colors ${
                userAnswer?.toString() === value
                  ? 'border-blue-500 bg-blue-50 text-blue-700'
                  : 'border-gray-200 hover:border-blue-300'
              }`}
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </motion.button>
          ))}
        </div>
      )}

      {question.type === 'short-answer' && (
        <input
          type="text"
          value={userAnswer as string || ''}
          onChange={(e) => onAnswer(e.target.value)}
          className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500"
          placeholder="Type your answer..."
        />
      )}
    </div>
  );
}