import { BookOpen, Upload, Brain } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { useAuthStore } from '../lib/store';
import { useState } from 'react';
import AuthModal from './auth/AuthModal';

export default function Hero() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleGetStarted = () => {
    if (user) {
      navigate('/create');
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center text-center pt-20 pb-16">
          <div className="flex items-center gap-2 mb-4">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900">
              Master Your Learning with
              <span className="text-blue-600"> AI-Powered</span> Quizzes
            </h1>
            <span className="bg-blue-100 text-blue-600 px-2 py-1 rounded text-sm font-semibold">BETA</span>
          </div>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl">
            Create a free account today and transform any topic or study material into engaging quizzes. 
            Upload PDFs or Word documents and let our AI help you learn smarter, not harder.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <Button size="lg" onClick={handleGetStarted}>
              {user ? 'Create New Quiz' : 'Get Started Free'}
            </Button>
          </div>
          {!user && (
            <p className="mt-4 text-sm text-gray-600">
              No credit card required • Currently in Beta
            </p>
          )}
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto py-16">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
            <BookOpen className="w-12 h-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Topic-Based Quizzes</h3>
            <p className="text-gray-600 text-center">
              Enter any subject and get instant, customized questions
            </p>
          </div>
          
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
            <Upload className="w-12 h-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Document Upload</h3>
            <p className="text-gray-600 text-center">
              Generate quizzes from your PDFs and Word documents
            </p>
          </div>
          
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
            <Brain className="w-12 h-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Smart Learning</h3>
            <p className="text-gray-600 text-center">
              AI adapts questions based on your performance
            </p>
          </div>
        </div>
      </div>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)}
        mode="signup"
      />
    </div>
  );
}