import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SettingsIcon } from 'lucide-react';
import { useAuthStore } from '../lib/store';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { deleteUserAccount } from '../lib/services/user.service';
import AccountTypeInfo from '../components/account/AccountTypeInfo';
import ProfileSection from '../components/settings/ProfileSection';
import AccountSection from '../components/settings/AccountSection';
import UsageStats from '../components/settings/UsageStats';
import DangerZone from '../components/settings/DangerZone';
import DeleteConfirmationModal from '../components/settings/DeleteConfirmationModal';
import toast from 'react-hot-toast';

export default function Settings() {
  const navigate = useNavigate();
  const { user, profile, setProfile } = useAuthStore();
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveProfile = async (data: { firstName: string; lastName: string }) => {
    if (!user) return;

    setIsSaving(true);
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, data);

      setProfile({
        ...profile!,
        ...data
      });

      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!user || isDeleting) return;

    setIsDeleting(true);
    try {
      await deleteUserAccount(user);
      toast.success('Account deleted successfully');
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error('Failed to delete account');
      setIsDeleting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20 pb-12">
      <div className="container max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-4xl font-bold text-[#1D4ED8]">Settings</h1>
            <p className="text-gray-600 mt-2">Manage your account preferences</p>
          </div>
          <div className="p-3 bg-blue-50 rounded-xl">
            <SettingsIcon className="w-8 h-8 text-blue-600" />
          </div>
        </div>

        <div className="grid gap-6">
          <ProfileSection 
            profile={profile}
            onSave={handleSaveProfile}
            isLoading={isSaving}
          />

          <AccountSection 
            user={user}
            onShowAccountInfo={() => setShowAccountInfo(true)}
          />

          <UsageStats />

          <DangerZone 
            onDeleteAccount={() => setShowDeleteConfirm(true)}
          />
        </div>

        <AccountTypeInfo
          isOpen={showAccountInfo}
          onClose={() => setShowAccountInfo(false)}
        />

        <DeleteConfirmationModal
          isOpen={showDeleteConfirm}
          isDeleting={isDeleting}
          onConfirm={handleDeleteAccount}
          onCancel={() => setShowDeleteConfirm(false)}
        />
      </div>
    </div>
  );
}