import { logger } from '../../utils/logger';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export function validatePDFFile(file: File): void {
  if (!file) {
    throw new Error('No file provided');
  }

  if (file.type !== 'application/pdf') {
    logger.error('Invalid file type:', file.type);
    throw new Error('Please upload a PDF file');
  }

  if (file.size > MAX_FILE_SIZE) {
    logger.error('File too large:', file.size);
    throw new Error('File size must be less than 10MB');
  }

  if (file.size === 0) {
    throw new Error('File is empty');
  }
}