import { logger } from '../../utils/logger';
import { achievementStorage } from './storage.service';
import { Achievement } from '../../../types/achievements';

export class AchievementSyncService {
  private static instance: AchievementSyncService;
  private syncInProgress = false;

  private constructor() {}

  static getInstance(): AchievementSyncService {
    if (!AchievementSyncService.instance) {
      AchievementSyncService.instance = new AchievementSyncService();
    }
    return AchievementSyncService.instance;
  }

  async syncAchievements(userId: string, achievements: Achievement[]): Promise<void> {
    if (this.syncInProgress) {
      logger.debug('Achievement sync already in progress');
      return;
    }

    try {
      this.syncInProgress = true;
      logger.debug('Starting achievement sync');

      const userAchievements = await achievementStorage.getUserAchievements(userId);
      const unlockedIds = new Set(userAchievements.map(a => a.achievementId));

      // Ensure all unlocked achievements are properly stored
      for (const achievement of achievements) {
        if (achievement.unlockedAt && !unlockedIds.has(achievement.id)) {
          await achievementStorage.saveAchievement(userId, achievement.id);
          logger.info('Synced missing achievement:', achievement.id);
        }
      }

      logger.debug('Achievement sync completed');
    } catch (error) {
      logger.error('Error syncing achievements:', error);
      throw error;
    } finally {
      this.syncInProgress = false;
    }
  }
}

export const achievementSync = AchievementSyncService.getInstance();