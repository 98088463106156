import { QuizSettings, Question } from '../types/quiz';
import { generateCompletion } from './services/openai.service';
import { logger } from './utils/logger';

function createPrompt(settings: QuizSettings, content?: string): string {
  const topic = content || settings.topic;
  const questionType = settings.questionType === 'true-false' ? 
    'true/false questions with only true or false as options' : 
    'multiple choice questions with 4 options each';

  return `Generate ${settings.numberOfQuestions} ${settings.difficulty} level ${questionType} about ${topic}.
Focus on testing understanding of specific technical details, facts, and concepts from the content.
Do not ask about document structure, chapter numbers, or section titles.
Each question should test a specific piece of knowledge or understanding.
Include a helpful hint for each question that guides the user towards the answer without giving it away directly.

Return ONLY a JSON object in this exact format:
{
  "questions": [
    {
      "id": "q1",
      "text": "question text focusing on specific technical details",
      "type": "${settings.questionType}",
      ${settings.questionType === 'true-false' ? 
        '"options": ["true", "false"],' :
        '"options": ["option1", "option2", "option3", "option4"],'
      }
      "correctAnswer": ${settings.questionType === 'true-false' ? 
        '"true" or "false"' : 
        '"correct option text"'
      },
      "explanation": "brief explanation referencing specific content",
      "hint": "helpful hint that guides towards the answer without giving it away"
    }
  ]
}`;
}

export async function generateQuestions(settings: QuizSettings): Promise<Question[]> {
  try {
    if (!settings.topic && !settings.pdfFile) {
      throw new Error('Please provide either a topic or a PDF file');
    }

    logger.debug('Generating questions with settings', settings);
    const prompt = createPrompt(settings);
    const responseText = await generateCompletion(prompt);

    if (!responseText) {
      throw new Error('No response received from OpenAI');
    }

    const response = JSON.parse(responseText);
    if (!response.questions || !Array.isArray(response.questions)) {
      throw new Error('Invalid response format');
    }

    return response.questions;
  } catch (error) {
    logger.error('Error generating questions:', error);
    throw error;
  }
}