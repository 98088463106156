import { useState, useEffect } from 'react';
import { getUserAchievements } from '../services/achievements.service';
import { checkAndUpdateAchievements } from '../services/achievement-tracker.service';
import { Achievement } from '../../types/achievements';
import { useAuthStore } from '../store';
import { logger } from '../utils/logger';

export function useAchievements() {
  const { user } = useAuthStore();
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchAchievements() {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);

        // First check and update achievements
        await checkAndUpdateAchievements(user.uid);
        
        // Then fetch the latest achievements
        const userAchievements = await getUserAchievements(user.uid);
        
        if (mounted) {
          setAchievements(userAchievements);
        }
      } catch (error) {
        logger.error('Error fetching achievements:', error);
        if (mounted) {
          setError(error instanceof Error ? error : new Error('Failed to load achievements'));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchAchievements();

    return () => {
      mounted = false;
    };
  }, [user]);

  return { achievements, loading, error };
}