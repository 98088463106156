import { sendEmailVerification, User } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { logger } from '../utils/logger';
import { storageService } from './storage.service';
import toast from 'react-hot-toast';

const EMAIL_VERIFICATION_COOLDOWN = 60; // seconds

export async function createInitialUserProfile(user: User) {
  try {
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      email: user.email,
      firstName: user.displayName?.split(' ')[0] || '',
      lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
      createdAt: new Date()
    }, { merge: true });
  } catch (error) {
    logger.error('Error creating initial user profile:', error);
    throw error;
  }
}

export async function sendVerificationEmail(user: User): Promise<void> {
  try {
    if (!user) {
      throw new Error('No user signed in');
    }

    if (user.emailVerified) {
      throw new Error('Email is already verified');
    }

    const lastSentKey = `email_verification_${user.uid}`;
    const lastSent = storageService.get<number>(lastSentKey);
    
    if (lastSent) {
      const timeElapsed = (Date.now() - lastSent) / 1000;
      if (timeElapsed < EMAIL_VERIFICATION_COOLDOWN) {
        const remainingTime = Math.ceil(EMAIL_VERIFICATION_COOLDOWN - timeElapsed);
        throw new Error(`Please wait ${remainingTime} seconds before requesting another verification email`);
      }
    }

    await sendEmailVerification(user, {
      url: window.location.origin + '/settings',
      handleCodeInApp: true
    });

    storageService.set(lastSentKey, Date.now());
    logger.info('Verification email sent successfully');
  } catch (error: any) {
    logger.error('Error sending verification email:', error);
    
    if (error.code === 'auth/too-many-requests') {
      throw new Error('Too many attempts. Please try again later.');
    }
    
    throw error;
  }
}

export async function checkEmailVerification(user: User): Promise<boolean> {
  try {
    if (!user) return false;
    
    await user.reload();
    return user.emailVerified;
  } catch (error) {
    logger.error('Error checking email verification:', error);
    return false;
  }
}

export function isEmailVerified(user: User | null): boolean {
  return user?.emailVerified ?? false;
}