import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { logger } from '../utils/logger';
import { performanceMonitor } from '../monitoring/performance';

class AnalyticsService {
  private eventBuffer: Array<{ name: string; params?: Record<string, any> }> = [];
  private readonly BUFFER_SIZE = 10;
  private flushTimeout: NodeJS.Timeout | null = null;

  constructor() {
    // Flush events periodically
    setInterval(() => this.flushEvents(), 30000);
  }

  private async flushEvents() {
    if (this.eventBuffer.length === 0) return;

    try {
      await performanceMonitor.measureAsync('analytics_flush', async () => {
        const events = [...this.eventBuffer];
        this.eventBuffer = [];

        for (const event of events) {
          if (analytics) {
            logEvent(analytics, event.name, event.params);
          }
        }

        logger.debug('Analytics events flushed', { count: events.length });
      });
    } catch (error) {
      logger.error('Error flushing analytics events:', error);
      // Retry failed events
      this.eventBuffer.push(...this.eventBuffer);
    }
  }

  trackEvent(eventName: string, params?: Record<string, any>) {
    try {
      this.eventBuffer.push({ name: eventName, params });

      if (this.eventBuffer.length >= this.BUFFER_SIZE) {
        this.flushEvents();
      }
    } catch (error) {
      logger.error('Error tracking analytics event:', error);
    }
  }

  trackQuizCreation(userId: string, quizType: string) {
    this.trackEvent('quiz_created', {
      userId,
      quizType,
      timestamp: Date.now(),
    });
  }

  trackQuizCompletion(userId: string, quizId: string, score: number) {
    this.trackEvent('quiz_completed', {
      userId,
      quizId,
      score,
      timestamp: Date.now(),
    });
  }

  trackSubscription(userId: string, plan: string) {
    this.trackEvent('subscription_purchased', {
      userId,
      plan,
      timestamp: Date.now(),
    });
  }

  trackError(error: Error, context?: Record<string, any>) {
    this.trackEvent('error_occurred', {
      errorMessage: error.message,
      errorStack: error.stack,
      context,
      timestamp: Date.now(),
    });
  }

  trackPerformanceMetric(name: string, value: number, metadata?: Record<string, any>) {
    this.trackEvent('performance_metric', {
      name,
      value,
      metadata,
      timestamp: Date.now(),
    });
  }
}

export const analyticsService = new AnalyticsService();