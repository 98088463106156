import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Lightbulb } from 'lucide-react';

interface HintDisplayProps {
  hint: string;
  isVisible: boolean;
}

export default function HintDisplay({ hint, isVisible }: HintDisplayProps) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="overflow-hidden"
        >
          <div className="mt-4 p-4 bg-amber-50 border border-amber-100 rounded-lg">
            <div className="flex items-start gap-3">
              <Lightbulb className="w-5 h-5 text-amber-500 mt-0.5" />
              <div>
                <div className="font-medium text-amber-800 mb-1">Hint</div>
                <p className="text-amber-700">{hint}</p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}