import { logger } from '../../utils/logger';
import { validatePDFFile } from './pdf-validator';
import { parsePDFContent } from './pdf-parser';

export async function extractTextFromPDF(file: File): Promise<string> {
  try {
    validatePDFFile(file);
    
    const arrayBuffer = await file.arrayBuffer();
    if (!arrayBuffer || arrayBuffer.byteLength === 0) {
      throw new Error('Failed to read file content');
    }
    
    const text = await parsePDFContent(arrayBuffer);
    if (!text || text.trim().length === 0) {
      throw new Error('No text content found in PDF');
    }
    
    logger.debug('Successfully extracted text from PDF', { 
      fileSize: file.size,
      textLength: text.length 
    });
    
    return text;
  } catch (error) {
    logger.error('Error extracting text from PDF:', error);
    throw error instanceof Error ? error : new Error('Failed to process PDF file');
  }
}