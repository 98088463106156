import { logger } from '../utils/logger';

class StorageService {
  private prefix: string;

  constructor(prefix: string = 'quizgenius_') {
    this.prefix = prefix;
  }

  private getKey(key: string): string {
    return `${this.prefix}${key}`;
  }

  set(key: string, value: any, ttl?: number): void {
    try {
      const item = {
        value,
        timestamp: Date.now(),
        expiry: ttl ? Date.now() + (ttl * 1000) : null,
      };
      localStorage.setItem(this.getKey(key), JSON.stringify(item));
    } catch (error) {
      logger.error('Storage set error:', error);
    }
  }

  get<T>(key: string): T | null {
    try {
      const item = localStorage.getItem(this.getKey(key));
      if (!item) return null;

      const { value, expiry } = JSON.parse(item);
      
      if (expiry && Date.now() > expiry) {
        this.delete(key);
        return null;
      }

      return value as T;
    } catch (error) {
      logger.error('Storage get error:', error);
      return null;
    }
  }

  delete(key: string): void {
    try {
      localStorage.removeItem(this.getKey(key));
    } catch (error) {
      logger.error('Storage delete error:', error);
    }
  }

  clear(): void {
    try {
      Object.keys(localStorage)
        .filter(key => key.startsWith(this.prefix))
        .forEach(key => localStorage.removeItem(key));
    } catch (error) {
      logger.error('Storage clear error:', error);
    }
  }
}

export const storageService = new StorageService();