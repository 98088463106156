import React from 'react';
import { motion } from 'framer-motion';
import { 
  Brain, 
  Upload, 
  Target, 
  Trophy,
  Clock,
  BarChart3,
  History,
  Sparkles,
  Shield
} from 'lucide-react';

export default function Features() {
  const features = [
    {
      icon: Brain,
      title: "AI-Powered Quiz Generation",
      description: "Create custom quizzes on any topic using advanced AI technology that generates relevant and challenging questions."
    },
    {
      icon: Target,
      title: "Multiple Question Types",
      description: "Support for various question formats including multiple choice, true/false, and short answer questions."
    },
    {
      icon: Upload,
      title: "PDF Upload Support",
      description: "Generate quizzes directly from your study materials by uploading PDF documents."
    },
    {
      icon: Trophy,
      title: "Achievement System",
      description: "Track your progress with an engaging achievement system that rewards your learning milestones."
    },
    {
      icon: Clock,
      title: "Real-Time Progress",
      description: "Monitor your quiz progress in real-time with immediate feedback and explanations."
    },
    {
      icon: BarChart3,
      title: "Detailed Analytics",
      description: "View comprehensive performance statistics and track your improvement over time."
    },
    {
      icon: History,
      title: "Quiz History",
      description: "Access your complete quiz history and review past performance to identify areas for improvement."
    },
    {
      icon: Sparkles,
      title: "Adaptive Difficulty",
      description: "Choose from beginner, intermediate, or advanced difficulty levels to match your expertise."
    },
    {
      icon: Shield,
      title: "Secure Platform",
      description: "Your data is protected with enterprise-grade security and user authentication."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-20 pb-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            Features
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Discover all the powerful features that make QuizGenius the perfect platform for your learning journey
          </motion.p>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto"
        >
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <feature.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold ml-3">{feature.title}</h3>
              </div>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-16 text-center"
        >
          <div className="inline-flex items-center space-x-2 bg-blue-50 px-4 py-2 rounded-full text-blue-600">
            <Brain className="w-5 h-5" />
            <span>Currently in Beta - More features coming soon!</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}