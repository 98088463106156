import React, { useState } from 'react';
import { Brain, Bug } from 'lucide-react';
import { Link } from 'react-router-dom';
import BugReportModal from './BugReportModal';

export default function Footer() {
  const [showBugReport, setShowBugReport] = useState(false);

  return (
    <>
      <footer className="bg-gray-900">
        <div className="container mx-auto px-6 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            {/* Brand */}
            <div className="flex items-center space-x-2">
              <Brain className="w-5 h-5 text-blue-500" />
              <span className="text-white font-semibold">QuizGenius</span>
            </div>

            {/* Links */}
            <nav className="flex space-x-6">
              <Link to="/features" className="text-gray-400 hover:text-blue-400 text-sm transition-colors">
                Features
              </Link>
              <Link to="/faq" className="text-gray-400 hover:text-blue-400 text-sm transition-colors">
                FAQ
              </Link>
              <Link to="/privacy" className="text-gray-400 hover:text-blue-400 text-sm transition-colors">
                Privacy
              </Link>
              <Link to="/terms" className="text-gray-400 hover:text-blue-400 text-sm transition-colors">
                Terms
              </Link>
              <button 
                onClick={() => setShowBugReport(true)}
                className="text-gray-400 hover:text-blue-400 text-sm transition-colors flex items-center"
              >
                <Bug className="w-4 h-4 mr-1" />
                Report Bug
              </button>
            </nav>

            {/* Copyright */}
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} QuizGenius
            </div>
          </div>
        </div>
      </footer>

      <BugReportModal 
        isOpen={showBugReport} 
        onClose={() => setShowBugReport(false)} 
      />
    </>
  );
}