import { logger } from '../utils/logger';
import { Achievement } from '../../types/achievements';
import { achievementStorage } from './achievements/storage.service';
import { achievementSync } from './achievements/sync.service';
import { ACHIEVEMENTS } from './achievements/achievements.const';
import { getUserResults } from './quiz.service';

export async function getUserAchievements(userId: string): Promise<Achievement[]> {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }

    // Get unlocked achievements and quiz results
    const [unlockedAchievements, results] = await Promise.all([
      achievementStorage.getUserAchievements(userId),
      getUserResults(userId)
    ]);

    // Create a map of unlocked achievements
    const unlockedMap = new Map(
      unlockedAchievements.map(ua => [ua.achievementId, ua.unlockedAt])
    );

    // Process achievements with unlocked status and progress
    const processedAchievements = ACHIEVEMENTS.map(achievement => {
      const unlockedAt = unlockedMap.get(achievement.id);
      
      if (unlockedAt) {
        return {
          ...achievement,
          unlockedAt,
          progress: 100
        };
      }

      // Calculate progress for locked achievements
      let progress = 0;
      if (results.length > 0) {
        switch (achievement.id) {
          case 'first-quiz':
            progress = results.length > 0 ? 100 : 0;
            break;
          case 'high-scorer':
            const highestScore = Math.max(...results.map(r => r.score), 0);
            progress = (highestScore / 90) * 100;
            break;
          // Add other achievement progress calculations
        }
      }

      return {
        ...achievement,
        progress: Math.min(Math.round(progress), 99) // Cap at 99% until unlocked
      };
    });

    // Sync achievements to ensure consistency
    await achievementSync.syncAchievements(userId, processedAchievements);

    return processedAchievements;
  } catch (error) {
    logger.error('Error getting user achievements:', error);
    throw error;
  }
}