import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Question, QuizResult, QuizSettings } from '../../types/quiz';
import { validateAnswer } from '../utils/answer-validation';

interface QuizState {
  questions: Question[];
  currentQuestionIndex: number;
  userAnswers: Record<string, string | boolean>;
  hintsUsed: Record<string, boolean>;
  quizStartTime: number | null;
  quizHistory: QuizResult[];
  monthlyQuizCount: number;
  lastMonthReset: string | null;
  quizSettings: QuizSettings | null;
  
  // Actions
  setQuestions: (questions: Question[]) => void;
  setCurrentQuestionIndex: (index: number) => void;
  setUserAnswer: (questionId: string, answer: string | boolean) => void;
  useHint: (questionId: string) => void;
  addToHistory: (result: QuizResult) => void;
  incrementQuizCount: () => void;
  checkAndResetMonthlyCount: () => void;
  resetStore: () => void;
  setQuizSettings: (settings: QuizSettings) => void;
  calculateScore: () => {
    correct: number;
    total: number;
    percentage: number;
    timeSpent: number;
  };
}

export const useQuizStore = create<QuizState>()(
  persist(
    (set, get) => ({
      // ... existing state
      hintsUsed: {},

      // ... existing actions
      useHint: (questionId: string) => 
        set((state) => ({
          hintsUsed: { ...state.hintsUsed, [questionId]: true }
        })),

      resetStore: () => set({
        questions: [],
        currentQuestionIndex: 0,
        userAnswers: {},
        hintsUsed: {},
        quizStartTime: null,
        quizHistory: [],
        monthlyQuizCount: 0,
        lastMonthReset: null,
        quizSettings: null
      }),

      // ... rest of the store implementation remains the same
    }),
    {
      name: 'quiz-store',
      partialize: (state) => ({
        monthlyQuizCount: state.monthlyQuizCount,
        lastMonthReset: state.lastMonthReset,
      }),
    }
  )
);