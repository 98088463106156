import { DocumentType, ValidationResult } from './types';
import { logger } from '../../utils/logger';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_TYPES = new Set<DocumentType>(['pdf', 'doc', 'docx']);

export function validateDocument(file: File): ValidationResult {
  try {
    if (!file) {
      return { isValid: false, error: 'No file provided' };
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return { isValid: false, error: 'File size must be less than 10MB' };
    }

    if (file.size === 0) {
      return { isValid: false, error: 'File is empty' };
    }

    // Get file extension
    const extension = file.name.split('.').pop()?.toLowerCase() as DocumentType;
    
    if (!ALLOWED_TYPES.has(extension)) {
      return { 
        isValid: false, 
        error: 'Only PDF and Word documents (.pdf, .doc, .docx) are supported' 
      };
    }

    return { isValid: true };
  } catch (error) {
    logger.error('Error validating document:', error);
    return { isValid: false, error: 'Error validating file' };
  }
}