import React from 'react';
import { HelpCircle } from 'lucide-react';
import { motion } from 'framer-motion';

interface HintButtonProps {
  hint: string | undefined;
  onShowHint: () => void;
  hintsUsed: number;
  disabled?: boolean;
}

export default function HintButton({ hint, onShowHint, hintsUsed, disabled }: HintButtonProps) {
  if (!hint) return null;

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onShowHint}
      disabled={disabled}
      className={`inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium rounded-full transition-colors ${
        disabled
          ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
          : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
      }`}
      title={disabled ? 'No more hints available' : 'Show hint'}
    >
      <HelpCircle className="w-4 h-4" />
      <span>Hint</span>
      {hintsUsed > 0 && (
        <span className="w-4 h-4 text-xs flex items-center justify-center bg-blue-100 rounded-full">
          {hintsUsed}
        </span>
      )}
    </motion.button>
  );
}