import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Question, QuizResult, QuizSettings } from '../types/quiz';
import { UserProfile } from '../types/auth';
import { User } from 'firebase/auth';
import { validateAnswer, toBooleanValue } from './utils/answer-validation';

interface AuthState {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: UserProfile | null) => void;
  setLoading: (loading: boolean) => void;
}

interface QuizState {
  questions: Question[];
  currentQuestionIndex: number;
  userAnswers: Record<string, string | boolean>;
  quizStartTime: number | null;
  quizHistory: QuizResult[];
  monthlyQuizCount: number;
  lastMonthReset: string | null;
  quizSettings: QuizSettings | null;
  setQuestions: (questions: Question[]) => void;
  setCurrentQuestionIndex: (index: number) => void;
  setUserAnswer: (questionId: string, answer: string | boolean) => void;
  addToHistory: (result: QuizResult) => void;
  incrementQuizCount: () => void;
  checkAndResetMonthlyCount: () => void;
  resetStore: () => void;
  setQuizSettings: (settings: QuizSettings) => void;
  calculateScore: () => {
    correct: number;
    total: number;
    percentage: number;
    timeSpent: number;
  };
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  profile: null,
  loading: true,
  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (loading) => set({ loading })
}));

export const useQuizStore = create<QuizState>()(
  persist(
    (set, get) => ({
      questions: [],
      currentQuestionIndex: 0,
      userAnswers: {},
      quizStartTime: null,
      quizHistory: [],
      monthlyQuizCount: 0,
      lastMonthReset: null,
      quizSettings: null,

      setQuestions: (questions) => set({ 
        questions,
        currentQuestionIndex: 0,
        userAnswers: {},
        quizStartTime: Date.now()
      }),

      setCurrentQuestionIndex: (index) => set({ currentQuestionIndex: index }),

      setUserAnswer: (questionId, answer) => {
        set((state) => {
          const question = state.questions.find(q => q.id === questionId);
          if (!question) return state;

          // For true/false questions, store as actual boolean
          const processedAnswer = question.type === 'true-false' 
            ? toBooleanValue(answer)
            : answer;

          return {
            userAnswers: { ...state.userAnswers, [questionId]: processedAnswer },
          };
        });
      },

      addToHistory: (result) =>
        set((state) => ({
          quizHistory: [result, ...state.quizHistory].slice(0, 50),
        })),

      incrementQuizCount: () =>
        set((state) => {
          const now = new Date();
          const lastReset = state.lastMonthReset ? new Date(state.lastMonthReset) : null;
          
          if (!lastReset || lastReset.getMonth() !== now.getMonth()) {
            return {
              monthlyQuizCount: 1,
              lastMonthReset: now.toISOString()
            };
          }

          return {
            monthlyQuizCount: state.monthlyQuizCount + 1
          };
        }),

      checkAndResetMonthlyCount: () =>
        set((state) => {
          const now = new Date();
          const lastReset = state.lastMonthReset ? new Date(state.lastMonthReset) : null;
          
          if (!lastReset || lastReset.getMonth() !== now.getMonth()) {
            return {
              monthlyQuizCount: 0,
              lastMonthReset: now.toISOString()
            };
          }
          return state;
        }),

      resetStore: () => set({
        questions: [],
        currentQuestionIndex: 0,
        userAnswers: {},
        quizStartTime: null,
        quizHistory: [],
        monthlyQuizCount: 0,
        lastMonthReset: null,
        quizSettings: null
      }),

      setQuizSettings: (settings) => set({ quizSettings: settings }),

      calculateScore: () => {
        const state = get();
        let correct = 0;
        const total = state.questions.length;
        
        state.questions.forEach((question) => {
          if (validateAnswer(
            question.type,
            state.userAnswers[question.id],
            question.correctAnswer
          )) {
            correct++;
          }
        });

        const timeSpent = state.quizStartTime 
          ? Math.floor((Date.now() - state.quizStartTime) / 1000)
          : 0;

        return {
          correct,
          total,
          percentage: Math.round((correct / total) * 100),
          timeSpent
        };
      },
    }),
    {
      name: 'quiz-store',
      partialize: (state) => ({
        monthlyQuizCount: state.monthlyQuizCount,
        lastMonthReset: state.lastMonthReset,
      }),
    }
  )
);