import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Clock, Award, RotateCcw, ChevronRight, Calendar, 
  Trash2, ChevronDown, Target, Brain
} from 'lucide-react';
import Button from '../components/Button';
import { useAuthStore } from '../lib/store';
import { getUserResults, clearUserHistory } from '../lib/services/quiz.service';
import { QuizResult } from '../types/quiz';
import { formatDistanceToNow } from 'date-fns';
import toast from 'react-hot-toast';
import { useQuizStore } from '../lib/store';
import { prepareQuizRetake } from '../lib/services/quiz/quiz-retake.service';

interface GroupedQuiz {
  topic: string;
  attempts: QuizResult[];
  isExpanded: boolean;
}

export default function QuizHistory() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const { setQuestions, setQuizSettings } = useQuizStore();
  const [results, setResults] = useState<QuizResult[]>([]);
  const [groupedQuizzes, setGroupedQuizzes] = useState<GroupedQuiz[]>([]);
  const [loading, setLoading] = useState(true);
  const [showClearConfirm, setShowClearConfirm] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

  useEffect(() => {
    async function fetchResults() {
      if (!user) return;
      
      try {
        setLoading(true);
        const fetchedResults = await getUserResults(user.uid);
        setResults(fetchedResults);
        
        const grouped = fetchedResults.reduce((acc: GroupedQuiz[], result) => {
          const existingGroup = acc.find(g => g.topic === result.topic);
          if (existingGroup) {
            existingGroup.attempts.push(result);
          } else {
            acc.push({
              topic: result.topic,
              attempts: [result],
              isExpanded: false
            });
          }
          return acc;
        }, []);
        
        setGroupedQuizzes(grouped);
      } catch (error) {
        console.error('Error fetching results:', error);
        toast.error('Failed to load quiz history');
      } finally {
        setLoading(false);
      }
    }

    fetchResults();
  }, [user]);

  const handleClearHistory = async () => {
    if (!user || clearing) return;

    setClearing(true);
    try {
      await clearUserHistory(user.uid);
      setResults([]);
      setGroupedQuizzes([]);
      toast.success('Quiz history cleared successfully');
      setShowClearConfirm(false);
    } catch (error) {
      console.error('Error clearing history:', error);
      toast.error('Failed to clear quiz history');
    } finally {
      setClearing(false);
    }
  };

  const handleRetakeQuiz = (result: QuizResult) => {
    try {
      const { questions, settings } = prepareQuizRetake(
        result.questions,
        {
          topic: result.topic,
          questionType: result.questions[0].type,
          difficulty: result.difficulty,
          numberOfQuestions: result.questions.length
        }
      );
      
      setQuizSettings(settings);
      setQuestions(questions);
      navigate('/quiz');
    } catch (error) {
      console.error('Error retaking quiz:', error);
      toast.error('Failed to start quiz retake');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20 flex items-center justify-center">
        <div className="flex flex-col items-center">
          <Brain className="w-12 h-12 text-blue-500 animate-pulse mb-4" />
          <p className="text-gray-600">Loading your quiz history...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20 pb-12">
      <div className="container max-w-4xl mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
          <div>
            <h1 className="text-4xl font-bold text-[#1D4ED8]">Quiz History</h1>
            <p className="text-gray-600 mt-2">Track your learning journey</p>
          </div>
          <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
            {results.length > 0 && (
              <Button 
                variant="outline" 
                onClick={() => setShowClearConfirm(true)}
                className="flex items-center justify-center text-red-600 hover:text-red-700 hover:border-red-600 transition-all duration-300 w-full sm:w-auto"
              >
                <Trash2 className="w-4 h-4 mr-2" />
                Clear History
              </Button>
            )}
            <Button 
              onClick={() => navigate('/create')}
              className="flex items-center justify-center bg-blue-700 hover:bg-blue-800 transition-all duration-300 w-full sm:w-auto"
            >
              Create New Quiz
            </Button>
          </div>
        </div>

        {groupedQuizzes.length === 0 ? (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center py-16 bg-white rounded-2xl shadow-xl"
          >
            <Brain className="w-16 h-16 text-blue-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-2">No Quizzes Yet</h2>
            <p className="text-gray-600 mb-6">Start your learning journey by creating your first quiz</p>
            <Button 
              onClick={() => navigate('/create')}
              className="bg-blue-700 hover:bg-blue-800 transition-all duration-300"
            >
              Create Your First Quiz
            </Button>
          </motion.div>
        ) : (
          <div className="space-y-6">
            {groupedQuizzes.map((group) => (
              <motion.div
                key={group.topic}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100"
              >
                <button
                  onClick={() => setSelectedTopic(selectedTopic === group.topic ? null : group.topic)}
                  className="w-full p-6 flex items-center justify-between hover:bg-gray-50 transition-colors duration-300"
                >
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-blue-50 rounded-xl">
                      <Target className="w-6 h-6 text-blue-600" />
                    </div>
                    <div className="text-left">
                      <h3 className="text-xl font-bold text-gray-900">{group.topic}</h3>
                      <p className="text-sm text-gray-500">
                        {group.attempts.length} {group.attempts.length === 1 ? 'attempt' : 'attempts'}
                      </p>
                    </div>
                  </div>
                  <motion.div
                    animate={{ rotate: selectedTopic === group.topic ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ChevronDown className="w-6 h-6 text-gray-400" />
                  </motion.div>
                </button>

                <AnimatePresence>
                  {selectedTopic === group.topic && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="border-t border-gray-100"
                    >
                      {group.attempts.map((result, index) => (
                        <motion.div
                          key={result.quizId}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="p-6 hover:bg-gray-50 transition-colors duration-300 border-b border-gray-100 last:border-b-0"
                        >
                          <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center space-x-4">
                              <div className="flex items-center text-sm text-gray-500">
                                <Calendar className="w-4 h-4 mr-2" />
                                {formatDistanceToNow(result.createdAt, { addSuffix: true })}
                              </div>
                              <div className="flex items-center text-sm">
                                <Clock className="w-4 h-4 mr-2 text-blue-500" />
                                {result.timeSpent}s
                              </div>
                            </div>
                            <div className="flex items-center space-x-4">
                              <div className="text-right">
                                <div className="text-3xl font-bold text-gray-900">
                                  {result.score}%
                                </div>
                                <div className="text-sm text-gray-500">
                                  {result.totalQuestions} questions
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col sm:flex-row gap-3">
                            <Button
                              variant="outline"
                              onClick={() => handleRetakeQuiz(result)}
                              className="flex items-center justify-center hover:bg-blue-50 transition-colors duration-300 w-full sm:w-auto"
                            >
                              <RotateCcw className="w-4 h-4 mr-2" />
                              Retake Quiz
                            </Button>
                            <Button
                              onClick={() => navigate(`/quiz/${result.quizId}`)}
                              className="flex items-center justify-center bg-blue-700 hover:bg-blue-800 transition-all duration-300 w-full sm:w-auto"
                            >
                              View Details
                              <ChevronRight className="w-4 h-4 ml-2" />
                            </Button>
                          </div>
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        )}

        {/* Delete Confirmation Modal */}
        <AnimatePresence>
          {showClearConfirm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 backdrop-blur-sm"
            >
              <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
                className="bg-white rounded-2xl shadow-2xl max-w-md w-full p-6"
              >
                <div className="flex items-center space-x-2 text-red-600 mb-4">
                  <Trash2 className="w-6 h-6" />
                  <h2 className="text-2xl font-bold">Clear Quiz History</h2>
                </div>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to clear your entire quiz history? This action cannot be undone.
                </p>
                <div className="flex space-x-4">
                  <Button
                    variant="outline"
                    onClick={() => setShowClearConfirm(false)}
                    className="flex-1"
                    disabled={clearing}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleClearHistory}
                    className="flex-1 bg-red-600 hover:bg-red-700 transition-all duration-300"
                    disabled={clearing}
                  >
                    {clearing ? 'Clearing...' : 'Clear History'}
                  </Button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}