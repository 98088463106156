import React from 'react';
import { motion } from 'framer-motion';
import { Achievement } from '../../types/achievements';
import { formatDistanceToNow } from 'date-fns';

interface AchievementCardProps {
  achievement: Achievement;
}

export default function AchievementCard({ achievement }: AchievementCardProps) {
  const isLocked = !achievement.unlockedAt;
  const showProgress = isLocked && typeof achievement.progress === 'number';
  
  return (
    <motion.div
      whileHover={{ y: -5 }}
      className={`bg-white rounded-xl p-6 shadow-lg transition-all ${
        isLocked ? 'opacity-50' : ''
      }`}
    >
      <div className="flex flex-col items-center">
        <div className={`text-4xl mb-4 ${isLocked ? 'grayscale' : ''}`}>
          {achievement.icon}
        </div>
        
        <h3 className="text-lg font-semibold mb-2">{achievement.name}</h3>
        <p className="text-gray-600 text-sm text-center mb-3">
          {achievement.description}
        </p>
        
        {isLocked ? (
          <div className="mt-auto">
            <div className="text-sm text-gray-500">
              {achievement.requirement}
            </div>
            {showProgress && (
              <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                <div
                  className="bg-blue-600 rounded-full h-2"
                  style={{ width: `${achievement.progress}%` }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="mt-auto text-sm text-gray-500">
            Unlocked {formatDistanceToNow(achievement.unlockedAt!, { addSuffix: true })}
          </div>
        )}
      </div>
    </motion.div>
  );
}