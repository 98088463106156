import { QuestionType } from '../../types/quiz';
import { logger } from './logger';

/**
 * Normalizes a string by removing extra whitespace, converting to lowercase,
 * and removing special characters
 */
function normalizeString(str: string): string {
  return str
    .toLowerCase()
    .trim()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .replace(/\s+/g, ' ');
}

/**
 * Converts various string representations of boolean values to actual booleans
 */
export function toBooleanValue(value: any): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  
  if (typeof value === 'string') {
    const normalized = value.toLowerCase().trim();
    return normalized === 'true' || normalized === '1' || normalized === 'yes';
  }
  
  return false;
}

/**
 * Validates if an answer is correct based on the question type
 */
export function validateAnswer(
  questionType: QuestionType,
  userAnswer: string | boolean | undefined,
  correctAnswer: string | boolean | undefined
): boolean {
  try {
    if (userAnswer === undefined || correctAnswer === undefined) {
      return false;
    }

    // Handle true/false questions
    if (questionType === 'true-false') {
      const userBool = toBooleanValue(userAnswer);
      const correctBool = toBooleanValue(correctAnswer);
      return userBool === correctBool;
    }

    // Handle multiple choice and short answer questions
    const normalizedUserAnswer = normalizeString(String(userAnswer));
    const normalizedCorrectAnswer = normalizeString(String(correctAnswer));

    // For multiple choice, exact match is required
    if (questionType === 'multiple-choice') {
      return normalizedUserAnswer === normalizedCorrectAnswer;
    }

    // For short answer, we can implement more flexible matching
    if (questionType === 'short-answer') {
      // Check for exact match first
      if (normalizedUserAnswer === normalizedCorrectAnswer) {
        return true;
      }

      // Check if user answer contains all key terms from correct answer
      const correctTerms = normalizedCorrectAnswer.split(' ');
      const userTerms = normalizedUserAnswer.split(' ');
      return correctTerms.every(term => userTerms.includes(term));
    }

    return false;
  } catch (error) {
    logger.error('Error validating answer:', error);
    return false;
  }
}

/**
 * Calculates the similarity between two strings (for potential future use)
 */
export function calculateStringSimilarity(str1: string, str2: string): number {
  const s1 = normalizeString(str1);
  const s2 = normalizeString(str2);
  
  if (s1 === s2) return 1;
  if (s1.length === 0 || s2.length === 0) return 0;
  
  const pairs1 = new Set(getPairs(s1));
  const pairs2 = new Set(getPairs(s2));
  const union = new Set([...pairs1, ...pairs2]);
  const intersection = new Set([...pairs1].filter(x => pairs2.has(x)));
  
  return (2.0 * intersection.size) / (pairs1.size + pairs2.size);
}

/**
 * Helper function to get character pairs from a string
 */
function getPairs(str: string): string[] {
  const pairs = [];
  for (let i = 0; i < str.length - 1; i++) {
    pairs.push(str.slice(i, i + 2));
  }
  return pairs;
}