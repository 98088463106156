import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Brain, Menu, X } from 'lucide-react';
import Button from './Button';
import AuthModal from './auth/AuthModal';
import UserMenu from './navigation/UserMenu';
import { useAuthStore } from '../lib/store';
import { auth } from '../lib/firebase';

export default function Header() {
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, profile } = useAuthStore();

  const handleAuthClick = (mode: 'signin' | 'signup') => {
    setAuthMode(mode);
    setShowAuthModal(true);
    setIsMenuOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
      setIsMenuOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fullName = profile ? `${profile.firstName} ${profile.lastName}` : user?.email?.split('@')[0];

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50 bg-white border-b">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to={user ? '/dashboard' : '/'} className="flex items-center space-x-2">
              <Brain className="w-8 h-8 text-blue-600" />
              <div className="flex flex-col">
                <span className="text-xl font-bold">QuizGenius</span>
                <span className="text-xs text-blue-600">Beta</span>
              </div>
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-8">
              {user ? (
                <div className="relative group">
                  <button className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 py-2 px-3 rounded-lg hover:bg-gray-50 transition-colors">
                    <span className="font-medium">{fullName}</span>
                  </button>
                  <div className="absolute right-0 mt-1 w-56 bg-white rounded-xl shadow-lg py-2 border opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform group-hover:translate-y-0 translate-y-2">
                    <UserMenu onSignOut={handleSignOut} />
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => handleAuthClick('signin')}
                  >
                    Sign In
                  </Button>
                  <Button 
                    size="sm" 
                    onClick={() => handleAuthClick('signup')}
                  >
                    Get Started
                  </Button>
                </div>
              )}
            </nav>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 text-gray-600 hover:text-gray-900"
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t">
            <div className="container mx-auto px-4 py-4 space-y-4">
              {user ? (
                <>
                  <div className="border-b pb-4">
                    <div className="text-sm text-gray-500">Signed in as</div>
                    <div className="font-medium">{fullName}</div>
                  </div>
                  <UserMenu 
                    onSignOut={handleSignOut} 
                    onClose={() => setIsMenuOpen(false)} 
                    isMobile 
                  />
                </>
              ) : (
                <div className="space-y-4">
                  <Button
                    variant="outline"
                    className="w-full"
                    onClick={() => handleAuthClick('signin')}
                  >
                    Sign In
                  </Button>
                  <Button
                    className="w-full"
                    onClick={() => handleAuthClick('signup')}
                  >
                    Get Started
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </header>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)} 
        mode={authMode}
      />
    </>
  );
}