import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../lib/firebase';
import { useAuthStore } from '../../lib/store';
import { logger } from '../../lib/utils/logger';
import { getUserProfile } from '../../lib/services/user.service';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const { setUser, setProfile, setLoading } = useAuthStore();

  useEffect(() => {
    logger.debug('Setting up auth state listener');
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          logger.debug('User authenticated:', { uid: user.uid });
          const profile = await getUserProfile(user.uid);
          setUser(user);
          setProfile(profile);
        } else {
          logger.debug('No user authenticated');
          setUser(null);
          setProfile(null);
        }
      } catch (error) {
        logger.error('Error handling auth state change:', error);
      } finally {
        setLoading(false);
      }
    }, (error) => {
      logger.error('Auth state change error:', error);
      setLoading(false);
    });

    return () => {
      logger.debug('Cleaning up auth state listener');
      unsubscribe();
    };
  }, [setUser, setProfile, setLoading]);

  return children;
}