import { Question, QuizSettings } from '../../../types/quiz';
import { logger } from '../../utils/logger';

export function prepareQuizRetake(questions: Question[], settings: QuizSettings): {
  questions: Question[];
  settings: QuizSettings;
} {
  try {
    // Randomize the order of questions
    const shuffledQuestions = [...questions].sort(() => Math.random() - 0.5);

    // For multiple choice questions, also randomize the options
    const preparedQuestions = shuffledQuestions.map(q => ({
      ...q,
      options: q.type === 'multiple-choice' ? 
        [...(q.options || [])].sort(() => Math.random() - 0.5) : 
        q.options
    }));

    logger.debug('Quiz prepared for retake', { 
      questionCount: preparedQuestions.length,
      topic: settings.topic 
    });

    return {
      questions: preparedQuestions,
      settings: { ...settings }
    };
  } catch (error) {
    logger.error('Error preparing quiz retake:', error);
    throw new Error('Failed to prepare quiz for retake');
  }
}