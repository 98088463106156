import { logger } from '../utils/logger';

interface PerformanceMetric {
  name: string;
  duration: number;
  timestamp: number;
  metadata?: Record<string, any>;
}

interface WebVitalMetric {
  name: string;
  value: number;
  timestamp: number;
}

class PerformanceMonitor {
  private metrics: PerformanceMetric[] = [];
  private webVitals: WebVitalMetric[] = [];
  private readonly MAX_METRICS = 1000;
  private readonly MAX_VITALS = 100;

  measureAsync<T>(name: string, fn: () => Promise<T>, metadata?: Record<string, any>): Promise<T> {
    const start = performance.now();
    
    return fn().then((result) => {
      const duration = performance.now() - start;
      this.recordMetric(name, duration, metadata);
      return result;
    }).catch((error) => {
      const duration = performance.now() - start;
      this.recordMetric(name, duration, { ...metadata, error: true });
      throw error;
    });
  }

  measure<T>(name: string, fn: () => T, metadata?: Record<string, any>): T {
    const start = performance.now();
    
    try {
      const result = fn();
      const duration = performance.now() - start;
      this.recordMetric(name, duration, metadata);
      return result;
    } catch (error) {
      const duration = performance.now() - start;
      this.recordMetric(name, duration, { ...metadata, error: true });
      throw error;
    }
  }

  recordWebVital(name: string, value: number): void {
    const vital: WebVitalMetric = {
      name,
      value,
      timestamp: Date.now(),
    };

    this.webVitals.push(vital);

    if (this.webVitals.length > this.MAX_VITALS) {
      this.webVitals.shift();
    }

    // Log poor web vitals
    if (this.isVitalPoor(name, value)) {
      logger.warn('Poor web vital detected:', {
        name,
        value: this.formatVitalValue(name, value),
      });
    }
  }

  private isVitalPoor(name: string, value: number): boolean {
    const thresholds: Record<string, number> = {
      CLS: 0.1,    // Cumulative Layout Shift
      FID: 100,    // First Input Delay (ms)
      LCP: 2500,   // Largest Contentful Paint (ms)
      FCP: 1800,   // First Contentful Paint (ms)
      TTFB: 600,   // Time to First Byte (ms)
    };

    return value > (thresholds[name] || 0);
  }

  private formatVitalValue(name: string, value: number): string {
    if (name === 'CLS') {
      return value.toFixed(3);
    }
    return `${value.toFixed(0)}ms`;
  }

  private recordMetric(name: string, duration: number, metadata?: Record<string, any>) {
    const metric: PerformanceMetric = {
      name,
      duration,
      timestamp: Date.now(),
      metadata,
    };

    this.metrics.push(metric);

    if (this.metrics.length > this.MAX_METRICS) {
      this.metrics.shift();
    }

    if (duration > 1000) { // Log slow operations (>1s)
      logger.warn('Slow operation detected:', {
        name,
        duration: `${duration.toFixed(2)}ms`,
        metadata,
      });
    }
  }

  getMetrics(name?: string): PerformanceMetric[] {
    if (name) {
      return this.metrics.filter(m => m.name === name);
    }
    return this.metrics;
  }

  getWebVitals(name?: string): WebVitalMetric[] {
    if (name) {
      return this.webVitals.filter(v => v.name === name);
    }
    return this.webVitals;
  }

  getAverageMetric(name: string): number {
    const metrics = this.getMetrics(name);
    if (metrics.length === 0) return 0;
    
    const sum = metrics.reduce((acc, m) => acc + m.duration, 0);
    return sum / metrics.length;
  }

  getAverageVital(name: string): number {
    const vitals = this.getWebVitals(name);
    if (vitals.length === 0) return 0;
    
    const sum = vitals.reduce((acc, v) => acc + v.value, 0);
    return sum / vitals.length;
  }

  clearMetrics(): void {
    this.metrics = [];
    this.webVitals = [];
  }
}

export const performanceMonitor = new PerformanceMonitor();