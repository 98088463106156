import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';
import Button from '../Button';
import { UserProfile } from '../../types/auth';

interface ProfileSectionProps {
  profile: UserProfile | null;
  onSave: (data: { firstName: string; lastName: string }) => Promise<void>;
  isLoading: boolean;
}

export default function ProfileSection({ profile, onSave, isLoading }: ProfileSectionProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formData, setFormData] = useState({
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || ''
  });

  useEffect(() => {
    // Reset form data when profile changes
    setFormData({
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || ''
    });
  }, [profile]);

  useEffect(() => {
    // Check if current form data differs from original profile data
    const hasChanged = 
      formData.firstName !== profile?.firstName || 
      formData.lastName !== profile?.lastName;
    setHasChanges(hasChanged);
  }, [formData, profile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setFormData({
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || ''
    });
    setIsEditing(false);
  };

  const handleSubmit = async () => {
    await onSave(formData);
    setIsEditing(false);
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
      <h2 className="text-xl font-bold mb-6">Profile Settings</h2>
      <div className="space-y-6">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500 ${
                !isEditing ? 'bg-gray-50' : 'bg-white'
              }`}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500 ${
                !isEditing ? 'bg-gray-50' : 'bg-white'
              }`}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          {!isEditing ? (
            <Button 
              onClick={() => setIsEditing(true)}
              className="bg-blue-700 hover:bg-blue-800 transition-all duration-300"
            >
              Edit Profile
            </Button>
          ) : (
            <>
              <Button 
                variant="outline" 
                onClick={handleCancel}
                className="hover:bg-gray-50 transition-all duration-300"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleSubmit}
                disabled={!hasChanges || isLoading}
                className="flex items-center bg-blue-700 hover:bg-blue-800 transition-all duration-300"
              >
                {isLoading ? 'Saving...' : (
                  <>
                    <Save className="w-4 h-4 mr-2" />
                    Save Changes
                  </>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}