import React from 'react';
import { Sparkles, Crown, Star } from 'lucide-react';
import Button from '../Button';
import Modal from '../ui/Modal';

interface AccountTypeInfoProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AccountTypeInfo({ isOpen, onClose }: AccountTypeInfoProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Account Types"
      icon={<Sparkles className="w-6 h-6" />}
      actions={
        <Button onClick={onClose} className="w-full sm:w-auto">
          Got it
        </Button>
      }
    >
      <div className="space-y-6">
        {/* Current Plan */}
        <div className="bg-blue-50 rounded-xl p-4">
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="w-5 h-5 text-blue-600" />
            <h3 className="font-semibold">Essential (Current)</h3>
          </div>
          <p className="text-sm text-gray-600">
            You're currently on our Essential plan, which is free during our beta period.
            Enjoy unlimited access to our core features while we develop additional capabilities.
          </p>
        </div>

        {/* Coming Soon Plans */}
        <div className="space-y-4">
          <div className="border rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <Star className="w-5 h-5 text-purple-600" />
              <h3 className="font-semibold">Pro</h3>
              <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">Coming Soon</span>
            </div>
            <p className="text-sm text-gray-600">
              Enhanced features and capabilities for serious learners.
            </p>
          </div>

          <div className="border rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <Crown className="w-5 h-5 text-yellow-600" />
              <h3 className="font-semibold">Premium</h3>
              <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">Coming Soon</span>
            </div>
            <p className="text-sm text-gray-600">
              Ultimate learning experience with unlimited access and premium features.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}