import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore, useQuizStore } from '../lib/store';
import { quizAttemptService } from '../lib/services/quiz/quiz-attempt.service';
import { logger } from '../lib/utils/logger';
import toast from 'react-hot-toast';
import ResultsHeader from '../components/results/ResultsHeader';
import ResultsActions from '../components/results/ResultsActions';
import QuestionReview from '../components/results/QuestionReview';

export default function Results() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const attemptRecorded = useRef(false);
  const {
    questions,
    userAnswers,
    calculateScore,
    setQuestions,
    setQuizSettings,
    quizSettings
  } = useQuizStore();

  const { correct, total, timeSpent } = calculateScore();

  useEffect(() => {
    async function recordAttempt() {
      if (!user || !questions.length || !quizSettings || attemptRecorded.current) return;

      const score = Math.round((correct / total) * 100);

      try {
        attemptRecorded.current = true;
        await quizAttemptService.recordAttempt({
          userId: user.uid,
          quizId: quizSettings.quizId || crypto.randomUUID(),
          topic: quizSettings.topic,
          score,
          timeSpent,
          totalQuestions: total,
          questions,
          answers: userAnswers,
          createdAt: new Date(),
          difficulty: quizSettings.difficulty
        });
      } catch (error) {
        logger.error('Error recording quiz attempt:', error);
        toast.error('Failed to save quiz results');
      }
    }

    recordAttempt();
  }, [user, questions, quizSettings, correct, total, timeSpent, userAnswers]);

  const handleRetake = () => {
    if (!questions.length || !quizSettings) return;

    try {
      const retakeQuestions = [...questions].sort(() => Math.random() - 0.5);
      setQuestions(retakeQuestions);
      setQuizSettings(quizSettings);
      navigate('/quiz');
    } catch (error) {
      logger.error('Error retaking quiz:', error);
      toast.error('Failed to start quiz retake');
    }
  };

  if (!questions.length) {
    navigate('/create');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="container max-w-2xl mx-auto px-4">
        <ResultsHeader 
          score={correct} 
          totalQuestions={total} 
          quizId={quizSettings?.quizId} 
        />

        <div className="bg-white rounded-lg shadow-lg p-6">
          {questions.map((question, index) => (
            <QuestionReview
              key={question.id}
              question={question}
              userAnswer={userAnswers[question.id]}
              index={index}
            />
          ))}

          <ResultsActions onRetake={handleRetake} />
        </div>
      </div>
    </div>
  );
}