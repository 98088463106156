import { doc, getDoc, setDoc, updateDoc, deleteDoc, collection, query, where, getDocs, writeBatch, Timestamp } from 'firebase/firestore';
import { deleteUser as firebaseDeleteUser } from 'firebase/auth';
import { db } from '../firebase';
import { UserProfile } from '../../types/auth';
import { logger } from '../utils/logger';
import { validateUserProfile, validateProfileUpdate } from '../utils/validation';

export async function getUserProfile(userId: string): Promise<UserProfile | null> {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return null;

    const data = userDoc.data();
    return {
      ...data,
      createdAt: data.createdAt.toDate(),
      deletedAt: data.deletedAt?.toDate() || null,
      status: data.status || 'active'
    } as UserProfile;
  } catch (error) {
    logger.error('Error fetching user profile:', error);
    return null;
  }
}

export async function deleteUserAccount(user: any): Promise<void> {
  try {
    const batch = writeBatch(db);
    const userId = user.uid;

    // Soft delete user profile
    const userRef = doc(db, 'users', userId);
    batch.update(userRef, {
      deletedAt: Timestamp.now(),
      status: 'deleted'
    });

    // Archive user data
    const archiveRef = doc(db, 'deletedUsers', userId);
    const userData = (await getDoc(userRef)).data();
    batch.set(archiveRef, {
      ...userData,
      deletedAt: Timestamp.now()
    });

    // Delete user's quiz results
    const resultsRef = collection(db, 'results');
    const resultsQuery = query(resultsRef, where('userId', '==', userId));
    const resultsSnapshot = await getDocs(resultsQuery);
    resultsSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Delete user's achievements
    const achievementsRef = collection(db, 'userAchievements');
    const achievementsQuery = query(achievementsRef, where('userId', '==', userId));
    const achievementsSnapshot = await getDocs(achievementsQuery);
    achievementsSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Commit all deletions
    await batch.commit();

    // Delete the Firebase Auth user
    await firebaseDeleteUser(user);

    logger.info('User account deleted successfully', { userId });
  } catch (error) {
    logger.error('Error deleting user account:', error);
    throw new Error('Failed to delete user account');
  }
}

export async function reactivateAccount(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    if (userData.status !== 'deleted') {
      throw new Error('Account is not deleted');
    }

    // Check if within 30-day grace period
    const deletedAt = userData.deletedAt?.toDate();
    if (!deletedAt || Date.now() - deletedAt.getTime() > 30 * 24 * 60 * 60 * 1000) {
      throw new Error('Account recovery period has expired');
    }

    await updateDoc(userRef, {
      deletedAt: null,
      status: 'active',
      reactivatedAt: Timestamp.now()
    });

    logger.info('Account reactivated successfully', { userId });
  } catch (error) {
    logger.error('Error reactivating account:', error);
    throw error;
  }
}

export async function checkDeletedAccount(email: string): Promise<{ exists: boolean; userId?: string }> {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email), where('status', '==', 'deleted'));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return { exists: false };
    }

    const deletedUser = snapshot.docs[0];
    const deletedAt = deletedUser.data().deletedAt?.toDate();
    const isWithinGracePeriod = deletedAt && 
      (Date.now() - deletedAt.getTime() <= 30 * 24 * 60 * 60 * 1000);

    return {
      exists: isWithinGracePeriod,
      userId: isWithinGracePeriod ? deletedUser.id : undefined
    };
  } catch (error) {
    logger.error('Error checking deleted account:', error);
    return { exists: false };
  }
}