import { Update } from './types';

export const UPDATES: Update[] = [
  {
    id: 'question-hints',
    title: 'Question Hints Added',
    description: 'Need a little help? Each question now has a hint button to guide you in the right direction!',
    date: '2024-03-21'
  },
  {
    id: 'word-docs',
    title: 'Word Document Support',
    description: 'You can now upload Word documents (.doc, .docx) in addition to PDFs!',
    date: '2024-03-20'
  },
  {
    id: 'mobile-nav',
    title: 'Improved Mobile Navigation',
    description: 'Better mobile experience with enhanced navigation and touch support.',
    date: '2024-03-19'
  }
];