import React from 'react';
import { Clock, CreditCard, Shield } from 'lucide-react';

export default function UsageStats() {
  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
      <h2 className="text-xl font-bold mb-6">Usage Statistics</h2>
      <div className="grid md:grid-cols-3 gap-6">
        <div className="flex items-center space-x-4">
          <div className="p-3 bg-blue-50 rounded-xl">
            <Clock className="w-5 h-5 text-blue-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">Monthly Quizzes</p>
            <p className="text-xs text-blue-400">Coming Soon</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="p-3 bg-green-50 rounded-xl">
            <CreditCard className="w-5 h-5 text-green-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">Remaining</p>
            <p className="text-xs text-blue-400">Coming Soon</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="p-3 bg-purple-50 rounded-xl">
            <Shield className="w-5 h-5 text-purple-600" />
          </div>
          <div>
            <p className="font-medium">Account Status</p>
            <p className="text-gray-600">Active</p>
          </div>
        </div>
      </div>
    </div>
  );
}