import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { UpdatesList } from './UpdatesList';

interface UpdatesModalProps {
  onClose: () => void;
}

export function UpdatesModal({ onClose }: UpdatesModalProps) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 z-50 backdrop-blur-sm touch-none"
        onClick={onClose}
      />
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.9 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 50, scale: 0.9 }}
        className="fixed z-50 bg-white rounded-t-xl sm:rounded-xl shadow-xl w-full sm:w-[380px] max-h-[80vh] overflow-y-auto overscroll-contain
          bottom-0 sm:bottom-20 right-0 sm:right-4"
      >
        <div className="sticky top-0 bg-white px-4 py-3 border-b flex items-center justify-between">
          <h2 className="text-xl font-bold">What's New</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close updates"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-4">
          <UpdatesList />
        </div>
      </motion.div>
    </>
  );
}