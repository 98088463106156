import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle, Plus, Minus, Instagram } from 'lucide-react';
import { useState } from 'react';

interface FAQItem {
  question: string;
  answer: string;
}

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs: FAQItem[] = [
    {
      question: "What is QuizGenius?",
      answer: "QuizGenius is an AI-powered quiz platform that helps you learn and test your knowledge on any topic. Our advanced AI technology generates custom quizzes tailored to your learning needs."
    },
    {
      question: "How does the AI quiz generation work?",
      answer: "Our AI analyzes your chosen topic and generates relevant questions across different difficulty levels. You can also upload PDF study materials, and our AI will create questions based on your content."
    },
    {
      question: "What types of questions are available?",
      answer: "QuizGenius supports multiple question formats including multiple choice, true/false, and short answer questions. Each format is designed to test different aspects of your knowledge."
    },
    {
      question: "Is QuizGenius free to use?",
      answer: "Yes! QuizGenius is currently in beta and free to use. You can create up to 10 quizzes per month with your free account."
    },
    {
      question: "Can I track my progress?",
      answer: "Absolutely! QuizGenius provides detailed analytics of your performance, including quiz history, success rates, and areas for improvement. You can also earn achievements as you progress."
    },
    {
      question: "How do I upload my study materials?",
      answer: "Simply click 'Create Quiz' and use the PDF upload option. Our AI will analyze your materials and generate relevant questions. Currently, we support PDF files up to 10MB."
    },
    {
      question: "Are my study materials and data secure?",
      answer: "Yes, we take security seriously. All data is encrypted, and we use enterprise-grade security measures to protect your information. We never share your data with third parties."
    },
    {
      question: "What should I do if I find an incorrect question?",
      answer: "While our AI strives for accuracy, if you notice any issues, you can report them through the feedback option available on each question. We continuously improve our system based on user feedback."
    },
    {
      question: "Can I retake quizzes?",
      answer: "Yes! You can retake any quiz from your quiz history. Questions will be randomized to ensure a fresh learning experience each time."
    },
    {
      question: "How do achievements work?",
      answer: "Achievements are unlocked as you reach different milestones, such as completing your first quiz, achieving high scores, or maintaining study streaks. View your achievements in your profile."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-20 pb-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-center mb-4"
          >
            <HelpCircle className="w-12 h-12 text-blue-600" />
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            Frequently Asked Questions
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Find answers to common questions about QuizGenius
          </motion.p>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="max-w-3xl mx-auto space-y-4"
        >
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-50"
              >
                <span className="font-semibold text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <Minus className="w-5 h-5 text-blue-600" />
                ) : (
                  <Plus className="w-5 h-5 text-blue-600" />
                )}
              </button>
              {openIndex === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="px-6 py-4 bg-gray-50"
                >
                  <p className="text-gray-600">{faq.answer}</p>
                </motion.div>
              )}
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-12 text-center"
        >
          <p className="text-gray-600">
            Still have questions?{' '}
            <a
              href="https://instagram.com/quizgenius.io"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 inline-flex items-center"
            >
              <Instagram className="w-4 h-4 mr-1" />
              Contact our support team
            </a>
          </p>
        </motion.div>
      </div>
    </div>
  );
}