import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { logger } from '../../utils/logger';
import { QuizResult, QuizAttempt } from '../../../types/quiz';

export class QuizAttemptService {
  private static instance: QuizAttemptService;
  private processingAttempts: Set<string> = new Set();

  private constructor() {}

  static getInstance(): QuizAttemptService {
    if (!QuizAttemptService.instance) {
      QuizAttemptService.instance = new QuizAttemptService();
    }
    return QuizAttemptService.instance;
  }

  private createAttemptKey(quizId: string): string {
    return `${quizId}_${Date.now()}`;
  }

  async recordAttempt(result: QuizResult): Promise<void> {
    const attemptKey = this.createAttemptKey(result.quizId);
    
    try {
      if (this.processingAttempts.has(attemptKey)) {
        logger.debug('Attempt already being processed', { quizId: result.quizId });
        return;
      }
      
      this.processingAttempts.add(attemptKey);

      const quizRef = doc(db, 'results', result.quizId);
      const quizDoc = await getDoc(quizRef);

      const attempt: QuizAttempt = {
        score: result.score,
        timeSpent: result.timeSpent,
        completedAt: new Date()
      };

      if (quizDoc.exists()) {
        const existingData = quizDoc.data();
        const currentAttempts = existingData.attempts || [];
        
        await setDoc(quizRef, {
          ...existingData,
          ...result,
          attempts: [...currentAttempts, attempt],
          timesCompleted: currentAttempts.length + 1,
          lastAttemptAt: attempt.completedAt,
          bestScore: Math.max(result.score, existingData.bestScore || 0)
        });

        logger.info('Quiz attempt recorded', {
          quizId: result.quizId,
          attemptNumber: currentAttempts.length + 1
        });
      } else {
        await setDoc(quizRef, {
          ...result,
          attempts: [attempt],
          timesCompleted: 1,
          lastAttemptAt: attempt.completedAt,
          bestScore: result.score
        });

        logger.info('First quiz attempt recorded', { quizId: result.quizId });
      }
    } catch (error) {
      logger.error('Error recording quiz attempt:', error);
      throw error;
    } finally {
      setTimeout(() => {
        this.processingAttempts.delete(attemptKey);
      }, 5000);
    }
  }

  async getAttemptCount(quizId: string): Promise<number> {
    try {
      const quizRef = doc(db, 'results', quizId);
      const quizDoc = await getDoc(quizRef);
      
      if (!quizDoc.exists()) {
        return 1;
      }

      const attempts = quizDoc.data().attempts || [];
      return attempts.length + 1;
    } catch (error) {
      logger.error('Error getting attempt count:', error);
      return 1;
    }
  }

  async getQuizAttempts(quizId: string): Promise<QuizAttempt[]> {
    try {
      const quizRef = doc(db, 'results', quizId);
      const quizDoc = await getDoc(quizRef);
      
      if (!quizDoc.exists()) {
        return [];
      }

      return quizDoc.data().attempts || [];
    } catch (error) {
      logger.error('Error getting quiz attempts:', error);
      return [];
    }
  }
}

export const quizAttemptService = QuizAttemptService.getInstance();