import React from 'react';
import { UPDATES } from './data';

export function UpdatesList() {
  return (
    <div className="space-y-4">
      {UPDATES.map(update => (
        <div key={update.id} className="border-b border-gray-100 last:border-0 pb-4 last:pb-0">
          <h3 className="font-semibold text-gray-900">{update.title}</h3>
          <p className="text-gray-600 text-sm mt-1">{update.description}</p>
          <p className="text-gray-400 text-xs mt-2">
            {new Date(update.date).toLocaleDateString()}
          </p>
        </div>
      ))}
    </div>
  );
}