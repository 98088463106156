import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { logger } from './utils/logger';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

logger.info('Initializing Firebase');

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore with optimized settings
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalAutoDetectLongPolling: false, // Disable auto-detection
  ignoreUndefinedProperties: true,
});

// Enable offline persistence with enhanced error handling
try {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      logger.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      logger.warn('Browser does not support persistence');
    } else {
      logger.error('Error enabling persistence:', err);
    }
  });
} catch (error) {
  logger.error('Critical error initializing persistence:', error);
}

// Initialize Analytics if supported
let analytics = null;
isSupported().then(supported => {
  if (supported) {
    analytics = getAnalytics(app);
    logger.info('Firebase Analytics initialized');
  }
}).catch(error => {
  logger.error('Error initializing Firebase Analytics:', error);
});

// Add connection state listener
if (typeof window !== 'undefined') {
  window.addEventListener('online', () => {
    logger.info('Application is online');
  });
  
  window.addEventListener('offline', () => {
    logger.warn('Application is offline');
  });
}

export { app, auth, db, analytics };