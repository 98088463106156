import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AuthProvider from './components/auth/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import ScrollToTop from './components/ScrollToTop';
import NetworkStatus from './components/NetworkStatus';
import Header from './components/Header';
import Footer from './components/Footer';
import Breadcrumbs from './components/navigation/Breadcrumbs';
import WhatsNew from './components/updates/WhatsNew';
import Hero from './components/Hero';
import Dashboard from './pages/Dashboard';
import CreateQuiz from './pages/CreateQuiz';
import Quiz from './pages/Quiz';
import Results from './pages/Results';
import QuizHistory from './pages/QuizHistory';
import QuizDetails from './pages/QuizDetails';
import Settings from './pages/Settings';
import Achievements from './pages/Achievements';
import Features from './pages/Features';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ScrollToTop />
        <NetworkStatus />
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <Header />
          <Breadcrumbs />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={
                <PublicRoute>
                  <Hero />
                </PublicRoute>
              } />
              <Route path="/features" element={<Features />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/dashboard" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/create" element={
                <ProtectedRoute>
                  <CreateQuiz />
                </ProtectedRoute>
              } />
              <Route path="/quiz" element={
                <ProtectedRoute>
                  <Quiz />
                </ProtectedRoute>
              } />
              <Route path="/results" element={
                <ProtectedRoute>
                  <Results />
                </ProtectedRoute>
              } />
              <Route path="/history" element={
                <ProtectedRoute>
                  <QuizHistory />
                </ProtectedRoute>
              } />
              <Route path="/quiz/:quizId" element={
                <ProtectedRoute>
                  <QuizDetails />
                </ProtectedRoute>
              } />
              <Route path="/settings" element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              } />
              <Route path="/achievements" element={
                <ProtectedRoute>
                  <Achievements />
                </ProtectedRoute>
              } />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
          <Footer />
          <WhatsNew />
          <Toaster position="top-center" />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;