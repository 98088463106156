import { useNavigate } from 'react-router-dom';
import { useQuizStore } from '../lib/store';
import { prepareQuizRetake } from '../lib/services/quiz/quiz-retake.service';
// ... other imports remain the same

export default function QuizDetails() {
  const navigate = useNavigate();
  const { setQuestions, setQuizSettings } = useQuizStore();
  // ... other existing code ...

  const handleRetakeQuiz = () => {
    if (!quiz) return;

    try {
      const { questions, settings } = prepareQuizRetake(
        quiz.questions,
        {
          topic: quiz.topic,
          questionType: quiz.questions[0].type,
          difficulty: quiz.difficulty,
          numberOfQuestions: quiz.questions.length
        }
      );

      setQuizSettings(settings);
      setQuestions(questions);
      navigate('/quiz');
    } catch (error) {
      console.error('Error retaking quiz:', error);
      toast.error('Failed to start quiz retake');
    }
  };

  // ... rest of the component
}