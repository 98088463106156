import React from 'react';
import { motion } from 'framer-motion';
import { ScrollText, Instagram } from 'lucide-react';

export default function Terms() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-20 pb-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-center mb-4"
          >
            <ScrollText className="w-12 h-12 text-blue-600" />
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            Terms of Service
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-gray-600"
          >
            Last updated: {new Date().toLocaleDateString()}
          </motion.p>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="prose prose-lg max-w-none"
        >
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-600">
              By accessing and using QuizGenius, you agree to be bound by these Terms of Service.
              If you disagree with any part of these terms, you may not access our service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2. User Accounts</h2>
            <p className="text-gray-600 mb-4">
              When you create an account with us, you must provide accurate and complete information.
              You are responsible for:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Maintaining the security of your account</li>
              <li>All activities that occur under your account</li>
              <li>Notifying us immediately of any unauthorized use</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">3. Acceptable Use</h2>
            <p className="text-gray-600 mb-4">
              You agree not to:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Share account credentials</li>
              <li>Use the service for any illegal purposes</li>
              <li>Upload malicious content</li>
              <li>Attempt to breach our security measures</li>
              <li>Resell or redistribute our services</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">4. Content</h2>
            <p className="text-gray-600">
              You retain all rights to any content you upload. By uploading content,
              you grant us a license to use it for generating quizzes. We reserve the right
              to remove any content that violates these terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">5. Service Availability</h2>
            <p className="text-gray-600">
              We strive to provide uninterrupted service but may need to perform maintenance
              or updates. We are not liable for any service interruptions or data loss.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4">6. Contact</h2>
            <p className="text-gray-600">
              For any questions about these Terms, please contact us on Instagram:{' '}
              <a 
                href="https://instagram.com/quizgenius.io" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:text-blue-700 inline-flex items-center"
              >
                <Instagram className="w-4 h-4 mr-1" />
                @quizgenius.io
              </a>
            </p>
          </section>
        </motion.div>
      </div>
    </div>
  );
}