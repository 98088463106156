import React from 'react';
import { motion } from 'framer-motion';
import { Award } from 'lucide-react';
import { useAchievements } from '../lib/hooks/useAchievements';
import AchievementCard from '../components/achievements/AchievementCard';

export default function Achievements() {
  const { achievements, loading } = useAchievements();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-20 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-blue-50 pt-20 pb-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <Award className="w-10 h-10 text-blue-600" />
          </div>
          <h1 className="text-3xl font-bold mb-2">Your Achievements</h1>
          <p className="text-gray-600">
            Track your progress and see what you've accomplished!
          </p>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto"
        >
          {achievements.map((achievement) => (
            <AchievementCard
              key={achievement.id}
              achievement={achievement}
            />
          ))}
        </motion.div>

        {achievements.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-600">
              No achievements yet. Start taking quizzes to earn badges!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}