import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { logger } from '../../utils/logger';
import { Achievement, UserAchievement } from '../../../types/achievements';

export class AchievementStorageService {
  private static instance: AchievementStorageService;
  private readonly COLLECTION = 'userAchievements';

  private constructor() {}

  static getInstance(): AchievementStorageService {
    if (!AchievementStorageService.instance) {
      AchievementStorageService.instance = new AchievementStorageService();
    }
    return AchievementStorageService.instance;
  }

  async isAchievementUnlocked(userId: string, achievementId: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.COLLECTION, `${userId}_${achievementId}`);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (error) {
      logger.error('Error checking achievement status:', error);
      return false;
    }
  }

  async saveAchievement(userId: string, achievementId: string): Promise<void> {
    try {
      const docRef = doc(db, this.COLLECTION, `${userId}_${achievementId}`);
      const docSnap = await getDoc(docRef);
      
      // Prevent duplicate unlocks
      if (docSnap.exists()) {
        logger.debug('Achievement already unlocked:', { userId, achievementId });
        return;
      }

      await setDoc(docRef, {
        userId,
        achievementId,
        unlockedAt: new Date(),
        syncTimestamp: Date.now()
      });

      logger.info('Achievement saved successfully:', { userId, achievementId });
    } catch (error) {
      logger.error('Error saving achievement:', error);
      throw error;
    }
  }

  async getUserAchievements(userId: string): Promise<UserAchievement[]> {
    try {
      const achievementsRef = collection(db, this.COLLECTION);
      const q = query(achievementsRef, where('userId', '==', userId));
      const snapshot = await getDocs(q);
      
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        unlockedAt: doc.data().unlockedAt.toDate()
      } as UserAchievement));
    } catch (error) {
      logger.error('Error fetching user achievements:', error);
      return [];
    }
  }
}

export const achievementStorage = AchievementStorageService.getInstance();