import { QuizResult } from '../../../types/quiz';

export function calculateStreakDays(results: QuizResult[]): number {
  if (results.length === 0) return 0;
  
  const sortedResults = [...results].sort((a, b) => 
    b.createdAt.getTime() - a.createdAt.getTime()
  );
  
  let streakDays = 1;
  let lastDate = sortedResults[0].createdAt;
  
  for (let i = 1; i < sortedResults.length && streakDays < 3; i++) {
    const currentDate = sortedResults[i].createdAt;
    const dayDiff = Math.floor(
      (lastDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    
    if (dayDiff === 1) {
      streakDays++;
      lastDate = currentDate;
    } else {
      break;
    }
  }
  
  return streakDays;
}

export function calculateCategoryProgress(results: QuizResult[]): {
  uniqueCategories: number;
  progress: number;
} {
  const categories = new Set(results.map(result => result.topic));
  return {
    uniqueCategories: categories.size,
    progress: (categories.size / 5) * 100
  };
}

export function calculateQuizMasterProgress(results: QuizResult[]): {
  quizCount: number;
  averageScore: number;
  progress: number;
} {
  if (results.length === 0) {
    return { quizCount: 0, averageScore: 0, progress: 0 };
  }

  const totalScore = results.reduce((sum, result) => sum + result.score, 0);
  const averageScore = totalScore / results.length;
  
  return {
    quizCount: results.length,
    averageScore,
    progress: (results.length / 50) * 100
  };
}