import React, { useEffect, useState } from 'react';
import { Trophy } from 'lucide-react';
import { motion } from 'framer-motion';
import { quizAttemptService } from '../../lib/services/quiz/quiz-attempt.service';

interface ResultsHeaderProps {
  score: number;
  totalQuestions: number;
  quizId?: string;
}

export default function ResultsHeader({ score, totalQuestions, quizId }: ResultsHeaderProps) {
  const [attemptCount, setAttemptCount] = useState<number>(1);
  const percentage = Math.round((score / totalQuestions) * 100);
  
  useEffect(() => {
    async function getAttemptCount() {
      if (!quizId) return;
      const count = await quizAttemptService.getAttemptCount(quizId);
      setAttemptCount(count);
    }
    
    getAttemptCount();
  }, [quizId]);

  return (
    <div className="text-center mb-8">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="inline-flex items-center justify-center w-20 h-20 bg-blue-50 rounded-full mb-4"
      >
        <Trophy className="w-10 h-10 text-blue-600" />
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl font-bold mb-2"
      >
        Quiz Complete!
      </motion.h1>
      <motion.div className="space-y-2">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-xl text-gray-600"
        >
          You scored {percentage}% ({score} out of {totalQuestions})
        </motion.p>
        {attemptCount > 1 && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-sm text-gray-500"
          >
            Attempt #{attemptCount}
          </motion.p>
        )}
      </motion.div>
    </div>
  );
}