import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';

const routes: Record<string, string> = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/create': 'Create Quiz',
  '/quiz': 'Take Quiz',
  '/results': 'Results',
  '/history': 'Quiz History',
  '/settings': 'Settings',
  '/achievements': 'Achievements'
};

export default function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  // Don't show breadcrumbs on home page
  if (location.pathname === '/') return null;

  return (
    <nav className="bg-white border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center h-12 text-sm">
          <Link
            to="/"
            className="text-gray-500 hover:text-blue-600 flex items-center"
          >
            <Home className="w-4 h-4" />
          </Link>

          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;

            return (
              <React.Fragment key={name}>
                <ChevronRight className="w-4 h-4 mx-2 text-gray-400" />
                {isLast ? (
                  <span className="text-blue-600 font-medium">
                    {routes[routeTo] || name}
                  </span>
                ) : (
                  <Link
                    to={routeTo}
                    className="text-gray-500 hover:text-blue-600"
                  >
                    {routes[routeTo] || name}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </nav>
  );
}