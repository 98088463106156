import React from 'react';
import { Upload, File, X } from 'lucide-react';
import { motion } from 'framer-motion';

interface DocumentUploadProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  isLoading?: boolean;
}

export default function DocumentUpload({ file, onFileChange, isLoading }: DocumentUploadProps) {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      onFileChange(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    onFileChange(null);
  };

  return (
    <div className="mt-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Upload Document
      </label>
      <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-xl ${
        isLoading ? 'bg-gray-50' : 'border-gray-300 hover:border-blue-400'
      } transition-colors`}>
        <div className="space-y-1 text-center">
          {!file ? (
            <>
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                  <span>Upload a file</span>
                  <input
                    type="file"
                    className="sr-only"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">
                PDF or Word documents up to 10MB
              </p>
            </>
          ) : (
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="flex items-center justify-center space-x-2"
            >
              <File className="h-8 w-8 text-blue-500" />
              <span className="text-sm text-gray-900">{file.name}</span>
              <button
                type="button"
                onClick={handleRemoveFile}
                className="text-red-500 hover:text-red-700 disabled:opacity-50"
                disabled={isLoading}
              >
                <X className="h-5 w-5" />
              </button>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
}