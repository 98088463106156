import React from 'react';
import { motion } from 'framer-motion';
import { useQuizStore } from '../../lib/store/quiz.store';
import Button from '../Button';
import HintButton from './HintButton';
import HintDisplay from './HintDisplay';
import QuestionOptions from './QuestionOptions';
import { Question } from '../../types/quiz';

interface QuizQuestionProps {
  question: Question;
  userAnswer?: string | boolean;
  onAnswer: (answer: string | boolean) => void;
  onNext: () => void;
  questionNumber: number;
  totalQuestions: number;
}

export default function QuizQuestion({ 
  question, 
  userAnswer, 
  onAnswer, 
  onNext,
  questionNumber,
  totalQuestions
}: QuizQuestionProps) {
  const { hintsUsed, useHint } = useQuizStore();
  const showHint = hintsUsed[question.id];

  const handleShowHint = () => {
    useHint(question.id);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="space-y-6"
    >
      {/* Progress bar */}
      <div className="flex justify-between items-center mb-4">
        <span className="text-sm text-gray-500">
          Question {questionNumber} of {totalQuestions}
        </span>
        <div className="w-full max-w-xs bg-gray-200 rounded-full h-2 ml-4">
          <div 
            className="bg-blue-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${(questionNumber / totalQuestions) * 100}%` }}
          />
        </div>
      </div>

      {/* Question and hint button */}
      <div className="flex justify-between items-start">
        <h3 className="text-xl font-semibold">{question.text}</h3>
        <HintButton 
          hint={question.hint}
          onShowHint={handleShowHint}
          hintsUsed={Object.keys(hintsUsed).length}
        />
      </div>

      {/* Hint display */}
      <HintDisplay 
        hint={question.hint || ''} 
        isVisible={showHint}
      />

      {/* Question options */}
      <QuestionOptions
        question={question}
        userAnswer={userAnswer}
        onAnswer={onAnswer}
      />

      {/* Next button */}
      {userAnswer !== undefined && (
        <motion.div 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="pt-4"
        >
          <Button onClick={onNext} className="w-full">
            {questionNumber === totalQuestions ? 'Finish Quiz' : 'Next Question'}
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
}