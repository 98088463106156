import React from 'react';
import { useNavigate } from 'react-router-dom';
import { History, RotateCcw } from 'lucide-react';
import Button from '../Button';

interface ResultsActionsProps {
  onRetake: () => void;
}

export default function ResultsActions({ onRetake }: ResultsActionsProps) {
  const navigate = useNavigate();
  
  const handleViewHistory = () => {
    navigate('/history');
  };

  return (
    <div className="mt-8 space-y-4">
      <Button 
        onClick={onRetake} 
        className="w-full bg-blue-700 hover:bg-blue-800 transition-all duration-300 flex items-center justify-center"
      >
        <RotateCcw className="w-4 h-4 mr-2" />
        Retake Quiz
      </Button>
      <Button 
        onClick={handleViewHistory} 
        variant="outline" 
        className="w-full hover:bg-gray-50 transition-all duration-300 flex items-center justify-center"
      >
        <History className="w-4 h-4 mr-2" />
        View Quiz History
      </Button>
    </div>
  );
}