import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PlusCircle, History, Award, AlertCircle, Brain } from 'lucide-react';
import Button from '../components/Button';
import { useQuizStore, useAuthStore } from '../lib/store';
import { getUserAchievements } from '../lib/services/achievements.service';
import { storageService } from '../lib/services/storage.service';

const MAX_FREE_QUIZZES = 10;

export default function Dashboard() {
  const navigate = useNavigate();
  const { user, profile } = useAuthStore();
  const { monthlyQuizCount } = useQuizStore();
  const [newAchievements, setNewAchievements] = useState(0);
  const remainingQuizzes = MAX_FREE_QUIZZES - monthlyQuizCount;

  useEffect(() => {
    async function checkNewAchievements() {
      if (!user) return;
      try {
        const achievements = await getUserAchievements(user.uid);
        const lastViewedKey = `achievements_viewed_${user.uid}`;
        const lastViewed = storageService.get<number>(lastViewedKey) || 0;

        const unlockedToday = achievements.filter(achievement => {
          if (!achievement.unlockedAt) return false;
          const unlockTime = new Date(achievement.unlockedAt).getTime();
          return unlockTime > lastViewed;
        });

        setNewAchievements(unlockedToday.length);
      } catch (error) {
        console.error('Error checking achievements:', error);
      }
    }

    checkNewAchievements();
  }, [user]);

  const handleViewAchievements = () => {
    if (user && newAchievements > 0) {
      storageService.set(`achievements_viewed_${user.uid}`, Date.now());
      setNewAchievements(0);
    }
    navigate('/achievements');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-2">
            Welcome back, {profile?.firstName || user?.email?.split('@')[0]}!
          </h1>
          {/* <p className="text-gray-600">
            You have {remainingQuizzes} free {remainingQuizzes === 1 ? 'quiz' : 'quizzes'} remaining this month
          </p> */}
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Rest of the component remains the same */}
          {/* Create New Quiz Card */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100"
          >
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold">Create New Quiz</h2>
              <div className="p-3 bg-blue-50 rounded-xl">
                <PlusCircle className="w-6 h-6 text-blue-600" />
              </div>
            </div>
            <p className="text-gray-600 mb-6">
              Generate a new AI-powered quiz on any topic
            </p>
            <Button 
              onClick={() => navigate('/create')}
              className="w-full bg-blue-700 hover:bg-blue-800 transition-all duration-300"
              disabled={remainingQuizzes <= 0}
            >
              Start Creating
            </Button>
            {remainingQuizzes <= 3 && remainingQuizzes > 0 && (
              <p className="mt-4 text-sm text-orange-600 flex items-center">
                <AlertCircle className="w-4 h-4 mr-1" />
                Only {remainingQuizzes} {remainingQuizzes === 1 ? 'quiz' : 'quizzes'} remaining
              </p>
            )}
          </motion.div>

          {/* Quiz History Card */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100"
          >
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold">Quiz History</h2>
              <div className="p-3 bg-purple-50 rounded-xl">
                <History className="w-6 h-6 text-purple-600" />
              </div>
            </div>
            <p className="text-gray-600 mb-6">
              View your past quiz results and performance
            </p>
            <Button 
              variant="outline"
              onClick={() => navigate('/history')}
              className="w-full hover:bg-gray-50 transition-all duration-300"
            >
              View History
            </Button>
          </motion.div>

          {/* Achievements Card */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100"
          >
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold">Achievements</h2>
              <div className="relative">
                <div className="p-3 bg-yellow-50 rounded-xl">
                  <Award className="w-6 h-6 text-yellow-600" />
                </div>
                {newAchievements > 0 && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    className="absolute -top-2 -right-2 w-6 h-6 bg-red-500 text-white text-xs rounded-full flex items-center justify-center font-bold"
                  >
                    {newAchievements}
                  </motion.div>
                )}
              </div>
            </div>
            <p className="text-gray-600 mb-6">
              {newAchievements > 0 
                ? `You've unlocked ${newAchievements} new achievement${newAchievements > 1 ? 's' : ''}!`
                : 'Track your learning progress and milestones'}
            </p>
            <Button 
              variant={newAchievements > 0 ? 'primary' : 'outline'}
              onClick={handleViewAchievements}
              className={`w-full ${
                newAchievements > 0 
                  ? 'bg-yellow-500 hover:bg-yellow-600 text-white' 
                  : 'hover:bg-gray-50'
              } transition-all duration-300`}
            >
              View Achievements
            </Button>
          </motion.div>
        </div>

        {remainingQuizzes <= 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-8 bg-white border border-orange-200 rounded-2xl p-6 shadow-xl"
          >
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-orange-50 rounded-xl">
                <AlertCircle className="w-6 h-6 text-orange-600" />
              </div>
              <div>
                <h3 className="font-bold text-lg text-orange-800 mb-1">Free Quiz Limit Reached</h3>
                <p className="text-orange-700 flex items-center space-x-4">
                  <span>You've used all your free quizzes for this month.</span>
                  <Button 
                    variant="outline"
                    size="sm"
                    onClick={() => navigate('/pricing')}
                    className="ml-4 border-orange-300 text-orange-700 hover:bg-orange-50"
                  >
                    Upgrade to Premium
                  </Button>
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}