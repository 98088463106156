import React, { useEffect, useRef } from 'react';
import QuizCreationForm from '../components/quiz/QuizCreationForm';
import { generateQuestions } from '../lib/api';
import toast from 'react-hot-toast';

export default function CreateQuiz() {
  const hasTestedRef = useRef(false);

  useEffect(() => {
    async function testApi() {
      if (hasTestedRef.current) return;
      
      if (!import.meta.env.VITE_OPENAI_API_KEY) {
        toast.error('OpenAI API key is not configured');
        return;
      }

      hasTestedRef.current = true;
      
      try {
        const testResult = await generateQuestions({
          topic: 'Test Topic',
          questionType: 'multiple-choice',
          difficulty: 'beginner',
          numberOfQuestions: 1
        });
        
        if (testResult && testResult.length > 0) {
          toast.success('API connection successful!');
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        toast.error(error instanceof Error ? error.message : 'Failed to connect to API');
        console.error('API test failed:', error);
      }
    }
    testApi();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-8">Create Your Custom Quiz</h1>
          <QuizCreationForm />
        </div>
      </div>
    </div>
  );
}