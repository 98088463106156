import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { initializeSentry } from './lib/monitoring/sentry';
import { ErrorBoundary } from './lib/monitoring/error-boundary';
import { reportWebVitals } from './lib/monitoring/web-vitals';

// Initialize monitoring
initializeSentry();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

createRoot(rootElement).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);

// Report web vitals
reportWebVitals(console.log);