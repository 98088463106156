import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bug, X } from 'lucide-react';
import Button from './Button';
import toast from 'react-hot-toast';
import { useAuthStore } from '../lib/store';

interface BugReportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function BugReportModal({ isOpen, onClose }: BugReportModalProps) {
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuthStore();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const form = e.currentTarget;
      const formData = new FormData(form);
      
      // Add user email if available
      if (user?.email) {
        formData.append('userEmail', user.email);
      }
      
      // Add form-name field required by Netlify
      formData.append('form-name', 'bug-report');

      // Submit to Netlify forms
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      toast.success('Bug report submitted successfully!');
      onClose();
      form.reset();
    } catch (error) {
      console.error('Form submission error:', error);
      toast.error('Failed to submit bug report. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-2xl shadow-xl max-w-md w-full p-6"
          >
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center space-x-2">
                <Bug className="w-6 h-6 text-blue-600" />
                <h2 className="text-2xl font-bold">Report a Bug</h2>
              </div>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form 
              onSubmit={handleSubmit}
              method="POST"
              data-netlify="true"
              name="bug-report"
              className="space-y-4"
            >
              {/* Hidden input for Netlify form name */}
              <input type="hidden" name="form-name" value="bug-report" />
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  required
                  rows={4}
                  className="w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500"
                  placeholder="Please describe the bug in detail..."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Steps to Reproduce
                </label>
                <textarea
                  name="steps"
                  required
                  rows={3}
                  className="w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500"
                  placeholder="What steps can we take to reproduce this bug?"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Your Email {user ? '(Optional)' : ''}
                </label>
                <input
                  type="email"
                  name="email"
                  defaultValue={user?.email || ''}
                  className="w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500"
                  placeholder="In case we need to contact you"
                />
              </div>

              <Button
                type="submit"
                disabled={submitting}
                className="w-full bg-blue-700 hover:bg-blue-800 transition-all duration-300"
              >
                {submitting ? 'Submitting...' : 'Submit Bug Report'}
              </Button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}