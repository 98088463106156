import OpenAI from 'openai';
import { logger } from '../utils/logger';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function generateCompletion(prompt: string): Promise<string | null> {
  try {
    logger.debug('Generating completion for prompt');
    
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are a quiz generator. Generate questions in valid JSON format only, with no additional text or markdown."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
      response_format: { type: "json_object" }
    });

    return completion.choices[0].message.content;
  } catch (error: any) {
    logger.error('Error generating completion:', error);
    
    if (error.status === 401) {
      throw new Error('Invalid OpenAI API key. Please check your configuration.');
    }
    
    throw new Error(error.message || 'Failed to generate completion');
  }
}